import { useEffect, useState } from "react";

function DayList({
    timetableWeekButtonStyle,
    backgroundButtonColor,
    previousWeekButtonImg,
    nextMonthButtonImg,
    api,
    setTrains, // менять стейт тренировок в календаре(проброс)
    statePage,  // какая страница открыта (активных записей или общая)
    idClient,
    handleChangeLoader, // прокинуть функцию отслеживания изменений дней и отображения лоадера
    styleText, // стиль текста под кнопкой дня недели для переключения
}
) {
    const [days, setDays] = useState([
        { day: "ВС", active: false, date: new Date(), },
        { day: "ПН", active: false, date: new Date(), },
        { day: "ВТ", active: false, date: new Date(), },
        { day: "СР", active: false, date: new Date(), },
        { day: "ЧТ", active: false, date: new Date(), },
        { day: "ПТ", active: false, date: new Date(), },
        { day: "СБ", active: false, date: new Date(), },
        { day: "ВС", active: false, date: new Date(), },
    ]);
    const [activeDayOfArray, setActiveDayOfArray] = useState(new Date().getDay());
    // const [isСhange, setIsChange] = useState(false); // слушать изменения для отрисовки лоадера

    // Работа со днями недеactiveDayOfArrayли
    function changeDates(sum) { // переключение 
        let newDates = days.map((day) => { // проставить активный день недели и передать даты ( парсинг в TimeTable)
            let date = day.date // в переменную дату дня недели
            sum ? date.setDate(day.date.getDate() + 7) // прибавить к каждому дню 7 дней
                : date.setDate(day.date.getDate() - 7) // вычесть 7 дней от каждого дня недели
            day.date = date; // перезаписать в объекте дату на новую
            new Date() === day.date ? day.active = true : day.active = false; // подсветить актуальный на дату день
            return day; // вернуть весь день
        })
        setDays(newDates);
    }



    function setNextWeek() { // переключить на след неделю в расписании
        changeDates(true)
    }

    function setPrevWeek() { // переключить на предыдущую неделю в расписании
        changeDates(false)
    }

    function getDateDayTodayOfWeek(date) { // получить(сформировать) дату (день и месяц) дня недели в расписании сегодня или выбранную
        return date.getDate() + "." +
            (date.getMonth() < 8 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) // добавить 1, тк Январь - "0" месяц и тд
    }


    // проставить активный день недели и передать даты ( парсинг в TimeTable)
    function changeDays(listDays) {
        let newDays = listDays.map((day, index) => { // проставить активный день недели и передать даты ( парсинг в TimeTable)
            if (new Date().getDay() === 0) { // если активный сегодняшний день это вс
                day.date = getDatesDaysIfSunday(index, day) // функция для рассчета дат если вс активный день
                return day
            }
            else {
                if (new Date().getDay() === index) {
                    day.date = new Date();
                    day.active = true;
                    return day;
                } else {
                    day.active = false;
                    day.date = getDatesDaysAroundTodayOfWeek(index);
                    return day;
                }
            }
        })
        setDays(newDays); // Перезаписываем стейт с помощью setDays
    }

    function changeDaysOnClick(listDays, indexActiveDay) {
        let newDays = listDays.map((day, index) => {
            if (indexActiveDay === 0) {
                day.date = getDatesDaysIfSunday(index, day);
                return day;
            } else if (indexActiveDay === index) {
                day.active = true;
                return day;
            } else {
                day.active = false;
                return day;
            }
        });
        setDays(newDays);
    }

    function selectDate(day, index, clientId) { // выбрать день для отображения тренировок
        if (typeof handleChangeLoader === 'function') {
            handleChangeLoader(true); // отследить изменения для отображения лоадера
        }
        setActiveDayOfArray(index); // передать в стейт индекс активного дня в массиве
        changeDaysOnClick(days, index); // Передаем выбранный день в функцию changeDays
        api.getTrainsOfDate(day)
            .then((trains) => {
                if (typeof handleChangeLoader === 'function') {
                    handleChangeLoader(false); // отследить изменения для отображения лоадера
                }
                let listTrains = trains.trains.sort((a, b) => new Date(a.dateOn) - new Date(b.dateOn))
                setTrains(listTrains);
            }).catch((err) => {
                if (typeof handleChangeLoader === 'function') {
                    handleChangeLoader(false); // отследить изменения для отображения лоадера
                }
                console.log(err);
            })
    }

    function getDatesDaysAroundTodayOfWeek(index) { // получить дату дня недели кроме текущего дня в расписании
        let date = new Date();
        let activeDay = new Date().getDay(); // день недели сейчас
        if (index > new Date().getDay()) { // если индекс в массиве больше, чем текущий день 
            date.setDate(date.getDate() + (index - activeDay)); // прибавить к текущей дате разницу
            return date
        } else if (index === 0) {
            date.setDate(date.getDate() + 10); // прибавить к текущей дате разницу
            return date;
        } else { // если день недели меньше, чем индекс в массиве, который равен текущему дню
            date.setDate(date.getDate() - (activeDay - index)); // убавить из текущей даты разницу
            return date;
        }
    }

    function getDatesDaysIfSunday(index) { // если сегодня вс
        let date = new Date();
        if (index === 7) { // найти след вс в массиве
            setActiveDayOfArray(7);
            return date; // присвоить ему дату сегодняшнего вс
        } else if (index < 7 && index > 0) {
            date.setDate(date.getDate() - (7 - index)); // вычесть индекс
            return date;
        } else {
            return date;
        }
    }

    useEffect(() => { // работа с расписанием, проставить активный день недели и даты при монтировании
        changeDays(days)
    }, []);

    useEffect(() => { // проставить активный день
    }, [activeDayOfArray])

    return (
        <div className="timetable__list">
            <div className="list-days">
                <button className={`list-days__button-change-week `} onClick={() => setPrevWeek()}>
                    <img src={previousWeekButtonImg} alt="изображение стрелки" />
                </button>
                {

                    days.map((day, index) => {
                       
                        let dayDate = getDateDayTodayOfWeek(day.date); // преобразовать дату для отображения и записать в переменную
                        return index !== 0 ? // спрятать первое вс
                            <div className="list-days__day-container" id={`day${index}`} key={`day-container-${index}`}>
                                <button
                                    id={`buttonDay${index}`}
                                    key={`buttonDay-${index}`}
                                    className={`list-days__day ${timetableWeekButtonStyle} ${day.active ? "list-days__day_active" : ""}`}
                                    onClick={() => selectDate(day.date, index, idClient)}>
                                    {day.day}
                                </button>
                                <p className={`list-days__day-date ${styleText}`}>{dayDate}</p>
                            </div>
                            : null
                    })
                }
                <button className={`list-days__button-change-week`} onClick={() => setNextWeek()}>
                    <img src={nextMonthButtonImg} alt="изображение стрелки" />
                </button>
            </div>
        </div>
    )
}

export default DayList;