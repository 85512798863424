
import { useEffect, useState } from "react";
import FormAdd from "../FormAdd/FormAdd";
import InfoTooltip from "../InfoTooltip/InfoTooltip";

function SignIn({ onSubmit, textMassageInfoTool }) {

    // Стейты, в которых содержятся значения инпута
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [validationMassegeLogin, setValidationMassegeLogin] = useState('Логин');
    const [validationMassegePassword, setValidationMassegePassword] = useState('Пароль');
    //Управление
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    //Инфотул
    const [isOpenInfoTool, setIsOpenInfoTool] = useState(false);
    const [messageInfoTool, setMessageInfoTool] = useState('')

    //передать данные  из инпутов
    function handleChangeLogin(e) {
        setLogin(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegeLogin('Логин');
        } else {
            setValidationMassegeLogin(e.target.validationMessage);
        }
    }

    function handleChangePassword(e) {
        setPassword(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegePassword('Пароль');
        } else {
            setValidationMassegePassword(e.target.validationMessage);
        }
    }

    //Передать данные = отправка на сервер 
    function handleSubmit(e) {
        e.preventDefault();
        onSubmit({
            login: login,
            password: password
        });
    }

    //Инфотул
    //Свернуть инфотул
    function handleCloseInfoTool() {
        setIsOpenInfoTool(false);
    }

    //Отслеживать изменения в инпута, кнопка задисейблена пока нет внесены изменения 
    useEffect(() => {
        const allowedCharactersRegex = /^[a-zA-Zа-яА-Я0-9]+$/;
        if (
            login === '' ||
            password === '' ||
            validationMassegePassword !== 'Пароль' ||
            validationMassegeLogin !== 'Логин' ||
            !allowedCharactersRegex.test(login) ||
            !allowedCharactersRegex.test(password)
        ) {
            setIsSubmitDisabled(true);
        } else {
            setIsSubmitDisabled(false);
        }

    }, [login, password, validationMassegePassword, validationMassegeLogin]);


    return (
        <div className="signin">
            {isOpenInfoTool ?
                <InfoTooltip
                    isOpen={isOpenInfoTool}
                    isClose={handleCloseInfoTool}
                    text={messageInfoTool}
                    backgoundColor={'signin'}
                />
                : <></>}
            <FormAdd
                isOpen={true} // стейт контролирующий открытие
                onSubmit={handleSubmit}
                text={'Авторизация'}
                textButtonSubmit={'Вход'}
                colorBackground={'signin'}
                validationOptions={isSubmitDisabled} // дисейблить сабмит если true
                styleAllForm={'signin__form'} // стиль всей формы
                buttonCloseDisable={true}
                buttonUnderText ={textMassageInfoTool}
                children={
                    <>
                        <div className="formadd__input-conainer">
                            <input value={login} type="text" placeholder="Логин" className={`signin formadd__input formadd__input_name_image`}
                                name="name-admin" id="name-admin" minLength="2" maxLength="30" onChange={handleChangeLogin} required />
                            <span className="formadd__text-error" id="error-firstname">{validationMassegeLogin}</span>
                        </div>
                        <div className="formadd__input-conainer">
                            <input value={password} type="text" placeholder="Пароль" className={`signin formadd__input formadd__input_name_image`}
                                name="password-admin" id="password-admin" minLength="1" maxLength="50" onChange={handleChangePassword} required />
                            <span className="formadd__text-error" id="error-firstname">{validationMassegePassword}</span>
                        </div>
                    </>
                }
            />
        </div >
    )
}

export default SignIn;