import TrainerPage from "../TrainerPage/TrainerPage"
import apiDark from '../../utils/ApiDark';

import nextMonthButtonImg from './../../images/next-week.svg'
import previousWeekButtonImg from './../../images/previous-week.svg'
import imgPersonalAccaunt from './../../images/personal.svg'
import imgList from './../../images/list-center-svgrepo-com.svg';
import imgTimeTable from './../../images/calendar-1-svgrepo-com.svg';

function TrainerPageDark({
    apiAuth,
}) {

    return (
        <TrainerPage
            backgroundColor={'dark'} // цвет фона
            textHeaderColor={'flex__title-color-text'} // цвет титульника
            styleHeaderBoarderColor={'admin-page__header-boarder'} // цвет полоски в хедере
            styleFooter={'dark__footer'} // стиль футера
            styleText={'dark__color-text'} // стиль текста
            textTitleColor={'flex__color-text'} // цвет титульников
            api={apiDark} // АПИ
            apiAuth={apiAuth}
            colorBackgroundForm={'dark'} // цвет фона формы добавления
            previousWeekButtonImg={previousWeekButtonImg} // изображение стрелки перекл дней недели
            nextMonthButtonImg={nextMonthButtonImg} // изображение стрелки перекл дней недели
            infoFlex={false} // давать ли информацию по абонементам Flex
            infoDark={true} // давать ли информацию по абонементам Dark
            imgPersonalAccaunt={imgPersonalAccaunt} // картинка кнопки перехода в ЛК
            imgList={imgList} // картинка кнопки тренировок(списка)
            imgTimeTable={imgTimeTable}// картинка кнопки календаря
            isSuperAdmin={false} // не страница суперадмина
        />
    )
}

export default TrainerPageDark;