import TrainerPage from "../TrainerPage/TrainerPage"
import apiFlex from '../../utils/ApiFlex';

import nextMonthButtonImg from './../../images/next-week.svg'
import previousWeekButtonImg from './../../images/previous-week.svg'
import imgPersonalAccaunt from './../../images/personal.svg'
import imgList from './../../images/list-center-svgrepo-com.svg';
import imgTimeTable from './../../images/calendar-1-svgrepo-com.svg';

function TrainerPageFlex({
    apiAuth, // АПИ Авторизаци
}) {

    return (
        <TrainerPage
            backgroundColor={'flex'} // цвет фона
            textHeaderColor={'flex__title-color-text'} // цвет титульника
            styleHeaderBoarderColor={'admin-page__header-boarder'} // цвет полоски в хедере
            api={apiFlex} // АПИ
            apiAuth={apiAuth} // АПИ Авторизации
            colorBackgroundForm={'flex'} // цвет фона формы добавления
            previousWeekButtonImg={previousWeekButtonImg} // изображение стрелки перекл дней недели
            nextMonthButtonImg={nextMonthButtonImg} // изображение стрелки перекл дней недели
            infoFlex={true} // давать ли информацию по абонементам Flex
            infoDark={false} // давать ли информацию по абонементам Dark
            imgPersonalAccaunt={imgPersonalAccaunt} // картинка кнопки перехода в ЛК
            imgList={imgList} // картинка кнопки тренировок(списка)
            imgTimeTable={imgTimeTable}// картинка кнопки календаря
            isSuperAdmin={false} // не страница суперадмина
            styleText={'flex__color-text'} // стиль текста
            textTitleColor={'flex__color-text'} // цвет титульников
        />
    )
}

export default TrainerPageFlex;