//Информация о клиенте(абонемент и общая инф)
//PageStudio --> this

import { useState } from 'react';
import Preloader from '../Preloader/Preloader';

function ClientInfo({
    nameClient,
    surnameClient,
    backgroundColor,
    phoneNumberClient,
    clickOnAbonement, // функция клика на абонемент
    clickOnClient, // функция клика на клиента
    client, // проверка есть ли клиент в БД, если нет, то не показывать инф об абонементе (true - клиент есть в БД)
    admin, // проверка страница admin или нет (true - admin)
    clickInfo, // true - работает клик на абонемент и имя в качестве кнопки
    fullInfoClient, // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
    abonementClientDarkGroupe,
    abonementClientDarkPersonal,
    abonementClientFlexGroupe,
    abonementClientFlexPersonal,
    infoFlex, // давать ли информацию по абонементам
    infoDark, // давать ли информацию по абонементам
    isLoader, // отображать лоадер или нет
    textColor, // цвет текста
    styleWidth, 
    textMainTitleColor, // цвет текста (в тч большие цифры)
    abonementDiscount,
    //isShowAllAbonements, // показывать инфу о всех абонементах или только о заведенных в базу ( true - всех, false - заведенных в базу)
}) {

    const [quantityVisitsGroupe, setQuantityVisitsGroupe] = useState(0);
    const [quantityVisitsPersonal, setQuantityVisitsPersonal] = useState(0);
    const [selectedAbonement, setSelectedAbonement] = useState(null); // передать данные абонемента при клике на него
    let indicatorGroupeAbonement;
    // ОТРИСОВАТЬ АБОНЕМЕНТЫ

    function getTrainingWord(count) { // слово тренировки (склонение)
        if (count >= 2 && count <= 4) {
            return 'тренировки';
        } else if (count === 1) {
            return 'тренировку';
        } else {
            return 'тренировок';
        }
    }

    // прбросить данные - тип абонемента и есть ли он для работы с формоф абонементов
    function onClickAbonement(abonement, options) {
        clickOnAbonement(abonement, options)
    }

    // пробросить данные клика на клиенте для просмотра инфо о нем
    function onClickName() {
        clickOnClient();
    }

    function noClick() { };

    // Отрисовать абонемент клиента для Клиента
    function renderClientsAbonement(clientAbonement) {
        // Отображение индикатора абонемента
        if (clientAbonement !== undefined && clientAbonement !== null) {
            const remainingVisits = clientAbonement.quantityVisits; // остаток тренировок
            const endDate = new Date(clientAbonement.dateTo); // окончание абонемента
            const today = new Date(); // текущая дата
            const isExpired = endDate < today; // проверка закончился или нет абонемент
            const isNoVisitsLeft = remainingVisits < 1; // проверка остались ли тренировки
            const daysLeft = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24)); // сколько дней до окончания абонемента

            let indicatorClassName;
            if (isNoVisitsLeft || isExpired) {
                indicatorClassName = 'about-client-page__abonement-indicator_false'; // красный
            } else if (daysLeft < 3 || remainingVisits === 1) {
                indicatorClassName = 'about-client-page__abonement-indicator_pre-false'; // желтый
            } else {
                indicatorClassName = 'about-client-page__abonement-indicator_true'; // зеленый
            }

            return client ? (

                <div className='client-info__container'>
                    {/* абонементы */}
                    {/* {clientAbonement.type.includes("персональные-сплит") ? <h3 className="about-client-page-title">Сплит</h3> :
                        clientAbonement.type.includes("персональные") ? <h3 className="about-client-page-title">Персональные</h3> : null} */}

                    <div className='client-info__container-info'>
                        <div className='client-info__container-indicator'>
                            <p className={`about-client-page__text ${textColor}`}>
                                Абонемент на {clientAbonement.quantityVisitsOn} {getTrainingWord(clientAbonement.quantityVisitsOn)}
                            </p>
                            <div className={`about-client-page__abonement-indicator client-info__indicator_client-page ${indicatorClassName}`}></div>
                        </div>
                    </div>

                    <p className={`about-client-page__text ${textColor}`}>
                        Срок действия до {endDate.toLocaleDateString('ru-RU', { day: 'numeric', month: 'numeric', year: '2-digit' })}
                    </p>

                    <p className={`about-client-page__text ${textColor}`}>
                        Осталось тренировок {clientAbonement.quantityVisits}
                    </p>
                </div>
            ) : null;
        } else { // если абонемента у клиента нет, то не отображать инфо
            return null;
        }
    }

    // Отрисовать абонемент клиента для Админа
    function renderPageAdminClientsAbonement(clientAbonement) {
        if (clientAbonement !== undefined && clientAbonement !== null) {
            // Если у клиента заведен абонемент (даже с истекшим сроком)
            if (client) {
                const startDate = new Date(clientAbonement.dateOn);
                const endDate = new Date(clientAbonement.dateTo);

                return (
                    <>
                        <div className={`client-info__button-abonements ${backgroundColor}`}>
                            <p className={`about-client-page__text ${textColor}`}>
                                Осталось занятий {clientAbonement.quantityVisits} из {clientAbonement.quantityVisitsOn}
                            </p>
                            <p className={`about-client-page__text ${textColor}`}>
                                действует с {formatDate(startDate)} по {formatDate(endDate)}
                            </p>
                        </div>
                    </>
                );
            } else {
                // Если клиента нет в БД
                return <></>;
            }
        } else {
            // Если у клиента не заведен никакой абонемент
            return <p className={`about-client-page__text ${textColor}`}>отсутсвует</p>;
        }
    }

    // Функция для форматирования даты в формате DD.MM.YY
    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        return `${day}.${month}.${year}`;
    }


    //Отрисовка персональногоабонемента на странице Админа
    function renderPageAdminClientsAbonementPersonal(clientAbonement) {
        // Управление индикатором
        let indicatorClassName;
        if (clientAbonement !== undefined && clientAbonement !== null) { // если абонемент есть (даже с истекшим сроком)
            const remainingVisits = clientAbonement.quantityVisits; // остаток тренировок
            const endDate = new Date(clientAbonement.dateTo); // окончание абонемента
            const today = new Date(); // текущая дата
            endDate.setHours(0, 0, 0, 0); // Установка времени в 00:00:00 для окончания абонемента
            today.setHours(0, 0, 0, 0); // Установка времени в 00:00:00 для текущего дня
            const isExpired = endDate < today; // проверка закончился или нет абонемент, возвращает false или true
            const isNoVisitsLeft = remainingVisits < 1; // проверка закончился или нет абонемент, возвращает false или true
            const daysLeft = Math.floor((endDate - today) / (1000 * 60 * 60 * 24)); // сколько дней до окончания абонемента осталось

            if (isNoVisitsLeft || isExpired) { // если закончились тренировки либо срок абонемента
                indicatorClassName = 'about-client-page__abonement-indicator_false'; // красный 
            } else if (daysLeft < 3 || remainingVisits === 1) { // если осталось меньше 3х дней до окончания абонемента или 1 тренировка
                indicatorClassName = 'about-client-page__abonement-indicator_pre-false'; // желтый
            } else { // если абонемент действует 
                indicatorClassName = 'about-client-page__abonement-indicator_true'; // зеленый
            }
        } else { // если абонемент не заведен в БД
            indicatorClassName = 'about-client-page__abonement-indicator_false'; // красный
        }

        return (
            <>
                {/* КЛИК НА АБОНЕМЕНТ */}
                <div
                    className="client-info__container-indicator-abonement"
                    onClick={() => {
                        clickInfo ? onClickAbonement(clientAbonement, 'персональный') : noClick();
                    }}
                >
                    <h3 className={`about-client-page-title ${textColor}`}>Персональный</h3>
                    <div className={`about-client-page__abonement-indicator client-info__container-indicator-abonement_admin ${indicatorClassName}`}></div>
                </div>
                {renderPageAdminClientsAbonement(clientAbonement)}
            </>
        );
    }

    // Отрисовка группового абонемента на странице Админа
    function renderPageAdminClientsAbonementGroupe(clientAbonement) {
        let indicatorClassName = 'about-client-page__abonement-indicator_false'; // Управление индикатором

        if (clientAbonement !== undefined && clientAbonement !== null) {
            const remainingVisits = clientAbonement.quantityVisits; // остаток тренировок
            const endDate = new Date(clientAbonement.dateTo); // окончание абонемента
            const today = new Date(); // текущий день
            const isExpired = endDate < today; // проверка закончился или нет абонемент, возвращает false или true
            const isNoVisitsLeft = remainingVisits < 1; // проверка закончился или нет абонемент, возвращает false или true
            const daysLeft = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24)); // сколько дней до окончания абонемента осталось

            if (isNoVisitsLeft || isExpired) {
                // Если закончились тренировки либо срок абонемента
                indicatorClassName = 'about-client-page__abonement-indicator_false'; // красный
                indicatorGroupeAbonement = 'about-client-page__abonement-indicator_false'; // красный
            } else if (daysLeft < 3 || remainingVisits === 1) {
                // Если осталось меньше 3х дней до окончания абонемента или 1 тренировка
                indicatorClassName = 'about-client-page__abonement-indicator_pre-false'; // желтый
                indicatorGroupeAbonement = 'about-client-page__abonement-indicator_pre-false'; // желтый
            } else {
                // Если абонемент действует
                indicatorClassName = 'about-client-page__abonement-indicator_true'; // зеленый
                indicatorGroupeAbonement = 'about-client-page__abonement-indicator_true'; // зеленый
            }
        }

        return (
            <>
                <div
                    className="client-info__container-indicator-abonement"
                    onClick={() => (clickInfo ? onClickAbonement(clientAbonement, 'групповой') : noClick())}
                >
                    <h3 className={`about-client-page-title ${textColor}`}>Групповой</h3>
                    <div
                        className={`about-client-page__abonement-indicator client-info__container-indicator-abonement_admin ${indicatorClassName}`}
                    ></div>
                </div>
                {renderPageAdminClientsAbonement(clientAbonement)}
            </>
        );
    }

    // Отрисовка индикатора отдельно (Групповой абонемент)
    function renderAbonementIndicator(clientAbonement) {
        let indicatorClassName;

        if (clientAbonement !== undefined && clientAbonement !== null) {
            const remainingVisits = clientAbonement.quantityVisits; // остаток тренировок
            const endDate = new Date(clientAbonement.dateTo); // окончание абонемента
            const today = new Date(); // текущий день
            const isExpired = endDate < today; // проверка закончился или нет абонемент, возвращает false или true
            const isNoVisitsLeft = remainingVisits < 1; // проверка закончился или нет абонемент, возвращает false или true
            const daysLeft = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24)); // сколько дней до окончания абонемента осталось

            if (isNoVisitsLeft || isExpired) {
                // если закончились тренировки либо срок абонемента
                indicatorClassName = 'about-client-page__abonement-indicator_false'; // красный
            } else if (daysLeft < 3 || remainingVisits === 1) {
                // если осталось меньше 3х дней до окончания абонемента или 1 тренировка
                indicatorClassName = 'about-client-page__abonement-indicator_pre-false'; // желтый
            } else {
                // если абонемент действует
                indicatorClassName = 'about-client-page__abonement-indicator_true'; // зеленый
            }
        }

        return (
            <>
                <div className={`about-client-page__abonement-indicator client-info__container-indicator-abonement_admin ${indicatorClassName}`}></div>
            </>
        );
    }




    // Отрисовать БОЛЬШИЕ ЦИФРЫ
    function renderBigQuantitys(clientAbonementGroupe, clientAbonementPersonal) {
        return < div className='client-info__container-main-quantityVisits' >
            {clientAbonementGroupe !== undefined ?
                <p className={`client-info__quantityVisits client-info__quantityVisits_groupe ${textColor}`}>{clientAbonementGroupe.quantityVisits}</p>
                : <></>}
            {clientAbonementPersonal !== undefined ?
                <p className={`client-info__quantityVisits ${textColor}`}>{clientAbonementPersonal.quantityVisits}</p>
                : <></>}
        </div >
    }

    // РЕНДЕР
    return (
        !admin ? // Если не админ ( подразумевается, что клиент)
            isLoader ?
                <Preloader /> :
                <div className={`client-info__container-main ${styleWidth}`}>
                    <div className='client-info__container-main-info'>
                        < h3 className={`about-client-page-title ${textColor}`} > {nameClient} {surnameClient}</h3 >
                        <>
                            {infoFlex ? // если давать инфо по Flex
                                <>
                                    {renderClientsAbonement(abonementClientFlexGroupe)}
                                    {renderClientsAbonement(abonementClientFlexPersonal)}
                                </>
                                :
                                <></>}
                            {infoDark ? // если давать инфо по Dark
                                <>
                                    {renderClientsAbonement(abonementClientDarkGroupe)}
                                    {renderClientsAbonement(abonementClientDarkPersonal)}
                                </>
                                :
                                <></>}
                        </>
                    </div>
                    {infoFlex ?
                        renderBigQuantitys(abonementClientFlexGroupe, abonementClientFlexPersonal)
                        : <></>}
                    {infoDark ?
                        renderBigQuantitys(abonementClientDarkGroupe, abonementClientDarkPersonal)
                        : <></>}
                </div>
            : // Если Админ
            fullInfoClient ? // полная информация по абонементу
                isLoader ? // лоадер пока загрузка
                    <Preloader />
                    :
                    <div className={`client-info__container-main ${styleWidth}`}>
                        <div className='client-info__container-main-info'>
                            < h3 className={`about-client-page-title ${textColor}`}
                                onClick={clickInfo ? onClickName : noClick} >
                                {nameClient} {surnameClient}
                            </h3 >
                            <p className={`about-client-page__text ${textColor}`}>{phoneNumberClient}</p>
                            {infoFlex ? // если давать инфо по Flex
                                <>
                                    {renderPageAdminClientsAbonementGroupe(abonementClientFlexGroupe)}
                                    {renderPageAdminClientsAbonementPersonal(abonementClientFlexPersonal)}
                                </>
                                : <></>}
                            {infoDark ? // если давать инфо по Dark
                                <>
                                    {renderPageAdminClientsAbonementGroupe(abonementClientDarkGroupe)}
                                    {renderPageAdminClientsAbonementPersonal(abonementClientDarkPersonal)}
                                </>
                                : <></>}
                        </div>
                    </div>
                :
                //краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                isLoader ? // показывать лоадер
                    <Preloader />
                    :
                    <div className={`client-info__container-main ${styleWidth}`}>
                        <div className='client-info__container-indicator-abonement'>
                            < h3 className={`about-client-page-title ${textColor}`}>
                                {nameClient} {surnameClient} {phoneNumberClient}
                            </h3 >
                            {infoFlex ?
                                renderAbonementIndicator(abonementClientFlexGroupe)
                                : <></>}
                            {infoDark ?
                                renderAbonementIndicator(abonementClientDarkGroupe)
                                : <></>}
                        </div>
                    </div>


    )

}

export default ClientInfo;

