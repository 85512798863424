import React from 'react';

function InfoTooltip({
    isOpen, // управлять открытием
    isClose, // управлять сворачиваением
    text, // передать текст
    showButton, // показывать кнопки или нет
    clickButtonOne, // дейсвтие при клике
    clickButtonTwo, // дейсвтие при клике
    clickButtonThree, // дейсвтие при клике
    backgoundColor, // цвет фона
    textButtonOne, // текст кнопки
    textButtonTwo, // текст кнопки
    textButtonThree, // текст кнопки
    isButtonStyleBorder, // стиль кнопок - квадратная рамка
}) {

    return (
        <div className={`info-tooltip info-tooltip__overlay ${isOpen ? "info-tooltip_show" : ""}`}>
            <div className={`info-tooltip__container ${backgoundColor}`}>
                <button className="info-tooltip__close-button" type="button" onClick={isClose} />
                <p className='tooltip__massage'>{text}</p>
                {showButton ?
                    <div className='tooltip__buttons-container'>
                        {textButtonOne ?
                            <button onClick={clickButtonOne} className={`tooltip__button ${isButtonStyleBorder ? 'tooltip__buttons-style' : ''}`}>{textButtonOne}</button>
                            : <></>}
                        {textButtonThree ?
                            <button onClick={clickButtonThree} className={`tooltip__button ${isButtonStyleBorder ? 'tooltip__buttons-style' : ''}`}>{textButtonThree}</button>
                            : <></>}
                        {textButtonTwo ?
                            <button onClick={clickButtonTwo} className={`tooltip__button ${isButtonStyleBorder ? 'tooltip__buttons-style' : ''}`}>{textButtonTwo}</button>
                            : <></>}
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    )
}


export default InfoTooltip;