import { useEffect, useState } from "react";

import ClientsTable from "../ClientsTable/ClientsTable";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import SearchForm from "../SearchForm/SearchForm";

import imgQR from "../../images/qrcode-svgrepo-com.svg";
import imgPlus from "../../images/add-plus-circle-svgrepo-com.svg"
import imgPhoneSearch from "../../images/mobile-svgrepo-com.svg"
import imgNameSurnameSearch from "../../images/search-svgrepo-com.svg"


function WindowClientsControl({
    isOpen,
    close,
    backgoundColorPage,
    textTitleColor,
    styleText,
    infoFlex,
    infoDark,
    api,
    training,
}) {

    //Клиенты
    const [clients, setClients] = useState([]) // список клиентов на тренировке для отображения
    const [waitingList, setWaitingList] = useState([]) // список клиентов в листе ожидания
    const [clickClient, setClickClient] = useState([]) // клиент, на котором кликнули
    const [findClients, setFindClients] = useState([]) // поиск для добавления на тренировку
    const [clickFindClient, setClickFindClient] = useState({}) // клик на найденном клиенте
    //Тренировка
    const [dateSelectedTraining, setDateSelectedTraining] = useState(""); // дата выбранной тренировки
    const [timeSelectedTraining, setTimeSelectedTraining] = useState(""); // время выбранной тренировки
    const [clickTraining, setClickTraining] = useState({})
    //Инфотул
    const [isOpenInfoTool, setIsOpenInfoTool] = useState(false);
    const [isButtonInfoTool, setButtonInfoTool] = useState(false); // отображать или нет кнопки в инфотуле
    const [textMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    const [isAddVisit, setIsAddVisit] = useState(true); // true - добавить визит, false, отменить
    //Инфотул добавления разовой тренировки
    const [isOpenOneTimeInfoTool, setIsOpenOneTimeInfoTool] = useState(false);
    const [textMassageOneTimeInfoTool, setMassageOneTimeInfoTool] = useState('');
    //Поиск клиента для добавления на тренировку
    const [isSearch, setIsSearch] = useState(false);
    const [searchType, setSearchType] = useState('phone');

    const optionsDate = {
        timeZone: 'Asia/Yekaterinburg',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    };

    const optionsTime = {
        timeZone: 'Asia/Yekaterinburg',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    };

    const formatterDate = new Intl.DateTimeFormat('ru-RU', optionsDate);
    const formatterTime = new Intl.DateTimeFormat('ru-RU', optionsTime);


    useEffect(() => {
        api.getTrainById(training._id)
            .then((training) => {
                setClickTraining(training)
                const formattedDate = formatterDate.format(new Date(training.train.dateOn));
                const formattedTime = formatterTime.format(new Date(training.train.dateOn));
                setDateSelectedTraining(formattedDate);
                setTimeSelectedTraining(formattedTime);
                setClients(training.train.listOfVisits);
                setWaitingList(training.train.waitingList);
            }).catch((err) => { console.log(err) });
    }, [isOpen, training,])

    //Инфотул
    function showInfo(text) {
        setIsOpenInfoTool(true);
        setButtonInfoTool(false);
        setTextMassageInfoTool(text);
        setTimeout(() => { setIsOpenInfoTool(false) }, 3000)
    }

    function closeInfoTool() {
        setIsOpenInfoTool(false);
    }

    //Отменить или добавить запись на тренировку у клиента
    function clickButtonOne() {
        if (isAddVisit) {
            getVisit()
        } else {
            removeVisit()
        }
    }

    function clickButtonTwo() {
        setIsOpenInfoTool(false);
    }

    function closeOneTimeInfoTool() {
        setIsOpenOneTimeInfoTool(false);
    }

    //Записать на разовую тренировку
    function clickButtonOneOneTimeInfoTool() {
        signUpForAoneTimeTraining()
    }

    //Свернуть форму
    function clickButtonTwoOneTimeInfoTool() {
        closeOneTimeInfoTool()
    }

    //Работа с клиентами из списка записавшихся на тренировку -------------------------------------------

    //функция для передачи в ClientTable, для отмены записи
    function сlickOnClients(idClient, client) {
        setIsAddVisit(false);
        console.log(client)
        setClickClient(client);
        setIsOpenInfoTool(true);
        setButtonInfoTool(true);
        let text = `отменить запись на тренировку для ${client.name} ${client.surname}?`
        setTextMassageInfoTool(text)
    }

    function сlickOnClientsNC() {
        //Заглушка
    }

    //Добавить визит на тренировку
    function getVisit() { // добавить клиента на тренировку
        api.addVisitOnTrain(training._id, clickFindClient._id, training.typeOfTraining)
            .then(() => {
                showInfo('Успешно')
            })
            .catch((err) => {
                showInfo(err.message)
            })

    }

    //Запись на разовую тренировку 
    function signUpForAoneTimeTraining() {
        console.log(clickFindClient._id, training._id, training.typeOfTraining)
        api.addOneTimeGroupeVisit(
            clickFindClient._id, // id клиента
            training._id, // id тренировки
            training.typeOfTraining, // тип тренировки
        ).then(() => {
            setIsOpenOneTimeInfoTool(false)
            showInfo('Клиент добавлен на разовую тренировку');
        }).catch((err) => {
            setIsOpenOneTimeInfoTool(false)
            showInfo(`Ошибка: ${err.message}`)
        });
    }

    //Отменить запись на тренировку
    function removeVisit() {
        api.removeVisitClientByAdmin(training._id, clickClient._id) // метод отмены для админа(может отменить после начала тренировки)
            .then(() => {
                const updateList = clients.filter((client) => client._id !== clickClient._id);
                setClients(updateList);
                showInfo('Успешно');
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 409) {
                    if (err.message === "Клиент с такими данными не записан на тренировку") {
                        showInfo("Клиент с такими данными не записан на тренировку")
                    } else if (err.message === "Отмена записи возможна только за 3 часа до начала тренировки") {
                        showInfo("Отмена записи возможна только за 3 часа до начала тренировки")
                    } else {
                        showInfo("Что-то пошло не так")
                    }
                } else {
                    showInfo(err.message)
                    console.log(err)
                }
            });
    }

    //ПОИСК 
    function clickSearch() {
        setIsSearch(true);
    }

    function clickQR() {

    }

    //Клик на найденном клиенте
    function сlickOnFindClient(idClient, client) {
        setClickFindClient(client);
        console.log(client)

        if (infoFlex) {
            if (training.typeOfTraining.includes('групповая')) {

                const abonementDateTo = new Date(client.abonementFlexGroupe.dateTo)
                const dateTraining = new Date(training.dateOn)
                const abonementsVacationVisits = client.abonementFlexGroupe.quantityVisits

                if (abonementDateTo <= dateTraining || Number(abonementsVacationVisits) === 0) { // если нет действующего абонемента
                    let descriptionOne = '';
                    let descriptioTwo = '';

                    if (abonementDateTo <= dateTraining) {
                        descriptionOne = 'окончание абонемента раньше тренировки'
                    }
                    if (Number(abonementsVacationVisits) === 0) {
                        descriptioTwo = 'закончились тренировки'
                    }

                    let description = <ul>
                        <li className="window-clients-control__description">{descriptionOne}</li>
                        <li className="window-clients-control__description">{descriptioTwo}</li>
                    </ul>

                    let text =
                        <div>
                            <p>ВНИМАНИЕ: записать на РАЗОВУЮ {client.name} {client.surname}?</p>
                            {description}
                        </div>

                    setIsOpenOneTimeInfoTool(true);
                    setMassageOneTimeInfoTool(text);
                } else { // если есть абонемент
                    let text = `записать на тренировку по абонементу ${client.name} ${client.surname}?`
                    setIsOpenInfoTool(true);
                    setTextMassageInfoTool(text);
                    setButtonInfoTool(true);
                    setIsAddVisit(true);
                }

            } else if (training.typeOfTraining.includes('сплит')) { // если тренировка сплит

                // if (client.abonementFlexPersonal.type.includes('сплит')) { // проверяем есть ли абонемент на сплит

                //     const abonementDateTo = new Date(client.abonementFlexPersonal.dateTo)
                //     const dateTraining = new Date(training.dateOn)
                //     const abonementsVacationVisits = client.abonementFlexGroupe.quantityVisits

                // } else { // если нет сплит абонемента

                //     let text =
                //         <div>
                //             <p>ВНИМАНИЕ: записать на РАЗОВУЮ {client.name} {client.surname}?</p>
                //             {description}
                //         </div>

                //     setIsOpenOneTimeInfoTool(true);
                //     setMassageOneTimeInfoTool(text);

                // let text = `записать на тренировку по абонементу ${client.name} ${client.surname}?`
                // setIsOpenInfoTool(true);
                // setTextMassageInfoTool(text);
                // setButtonInfoTool(true);
                // setIsAddVisit(true);
            }

        }
    }


    //SearchForm---------SearchForm---------SearchForm---------SearchForm---------SearchForm---------SearchForm---------SearchForm
    // управлять поиском по номеру или Имени
    function handleSearchType(type) {
        setSearchType(type)
    }

    //Сабмит поиска
    function onSubmitSearchForm(value) {
        if (searchType === "phone") {
            searchByPhoneNumber(value);
        } else if (searchType === "nameAndSurname") {
            searchByNameAndSurname(value);
        }
    }

    //Поиск по номеру телефона
    function searchByPhoneNumber(value) {
        const number = +value; // Преобразовать в число

        // если поиск по клиентам
        if (value.length <= 11 && !isNaN(number)) {
            api
                .getClientForNumber(number)
                .then((res) => {
                    let Clients = res.clients;

                    if (Clients.length > 0) {
                        setFindClients(Clients);
                    } else {
                        showInfo(
                            "Клиент с такими данными не найден, проверьте параметры ввода"
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            showInfo("Пожалуйста, введите корректный номер телефона.");
        }

    }

    //Главная функция поиска
    function searchByNameAndSurname(value) {
        let name;
        let surname;

        if (value.includes(" ")) {
            [surname, name] = value.split(" ");
            searchByFullName(surname, name);
        } else {
            searchByName(value);
        }
    }

    function searchByFullName(surname, name) {

        api
            .getClientForName(surname, name)
            .then((res) => {
                let Clients = res.clients;
                if (Clients.length > 0) {
                    setFindClients(Clients);
                } else {
                    // Проверяем не перепутаны ли местами фамилия и имя
                    api
                        .getClientForName(name, surname)
                        .then((res) => {
                            let Clients = res.clients;
                            if (Clients.length > 0) {
                                setFindClients(Clients);
                            } else {
                                showInfo(
                                    "Клиент с такими данными не найден, проверьте параметры ввода"
                                );
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    function searchByName(name) {
        api
            .getClientForName(name)
            .then((res) => {

                let Clients = res.clients;
                if (Clients.length > 0) {
                    setFindClients(Clients);
                } else {
                    searchBySurname(name);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function searchBySurname(surname) {

        api
            .getClientForName("", surname)
            .then((res) => {
                let Clients = res.clients;
                if (Clients.length > 0) {
                    console.log(Clients);
                    setFindClients(Clients);
                } else {
                    showInfo(
                        "Клиент с такими данными не найден, проверьте параметры ввода"
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }


    return (

        <div className={`window-clients-control ${backgoundColorPage}`}>
            {isOpenInfoTool ?
                <InfoTooltip
                    isOpen={isOpenInfoTool} // управлять открытием
                    isClose={closeInfoTool} // управлять сворачиваением
                    text={textMassageInfoTool} // передать текст
                    showButton={isButtonInfoTool} // показывать кнопки или нет
                    clickButtonOne={clickButtonOne} // дейсвтие при клике
                    clickButtonTwo={clickButtonTwo} // дейсвтие при клике
                    backgoundColor={backgoundColorPage} // цвет фона
                    textButtonOne={"Да"}
                    textButtonTwo={"Нет"}
                    isButtonStyleBorder={true} // true - без заливки фона
                />
                : <></>}
            {isOpenOneTimeInfoTool ?
                <InfoTooltip
                    isOpen={isOpenOneTimeInfoTool} // управлять открытием
                    isClose={closeOneTimeInfoTool} // управлять сворачиваением
                    text={textMassageOneTimeInfoTool} // передать текст
                    showButton={true} // показывать кнопки или нет
                    clickButtonOne={clickButtonOneOneTimeInfoTool} // дейсвтие при клике
                    clickButtonTwo={clickButtonTwoOneTimeInfoTool} // дейсвтие при клике
                    backgoundColor={backgoundColorPage} // цвет фона
                    textButtonOne={"Да"}
                    textButtonTwo={"Нет"}
                    isButtonStyleBorder={true} // true - без заливки фона
                />
                : <></>}
            {/* Список клиентов, записанных на тренировку */}
            <>
                <button className="window-clients-control__close-button" type="button" onClick={close} />
                <p className={`timetable__title ${textTitleColor} ${styleText}`}>{training.name} {dateSelectedTraining} {timeSelectedTraining}</p>
                {/* ДОБАВЛЕНИЕ КЛИЕНТОВ */}
                <p className={`timetable__title ${textTitleColor} ${styleText}`}>Добавить клиента</p>
                <div className="window-clients-control__buttons-container">
                    <img className="window-clients-control__button" src={imgPlus} onClick={clickSearch} alt="imgPlus" />
                    <img className="window-clients-control__button" src={imgQR} onClick={clickQR} alt="imgQR" />
                </div>
                {isSearch ?
                    <>
                        <SearchForm
                            handleSubmOnSearch={onSubmitSearchForm} // сабмит 
                            handleSearchType={handleSearchType} // управлять поиском по номеру или Имени
                            //styleSearchForm={styleSearchForm} // стиль формы поиска
                            styleTextSearchForm={styleText} // стиль текста
                            imgPhoneSearch={imgPhoneSearch} // картинка кнопки поиска по телефону
                            imgNameSurnameSearch={imgNameSurnameSearch} // картинка кнопки поиска по телефону
                        />
                        <ClientsTable
                            fullInfoClient={true} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                            clients={findClients} // список клиентов для отображения в таблице
                            handleActiveClientInfo={сlickOnFindClient} //управлять отображением кнопок Добавить клиента и др
                            infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                            infoDark={infoDark} // давать ли информацию по абонементам Dark
                            admin={true} // проверка страница admin или нет (true - admin)
                            handleNameClient={сlickOnClientsNC}// пробросить из ClientInfo для WA
                            handlePhoneNumberClient={сlickOnClientsNC} // пробросить из ClientInfo для WA
                            backgroundColor={'clients-table_background'} // стиль фона
                        />
                    </> : <></>}
                {/* СПИСОК КЛИЕНТОВ */}
                <h3 className={`timetable__title ${textTitleColor} ${styleText}`}>Список клиентов</h3>
                <ClientsTable
                    fullInfoClient={false} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                    clients={clients} // список клиентов для отображения в таблице
                    handleActiveClientInfo={сlickOnClients} //управлять отображением кнопок Добавить клиента и др
                    infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                    infoDark={infoDark} // давать ли информацию по абонементам Dark
                    admin={true} // проверка страница admin или нет (true - admin)
                    handleNameClient={сlickOnClientsNC}// пробросить из ClientInfo для WA
                    handlePhoneNumberClient={сlickOnClientsNC} // пробросить из ClientInfo для WA
                    backgroundColor={'clients-table_background'} // стиль фона
                />
                <h3 className={`timetable__title ${textTitleColor} ${styleText}`}>Лист ожидания</h3>
                <ClientsTable
                    fullInfoClient={false} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                    clients={waitingList} // список клиентов для отображения в таблице
                    handleActiveClientInfo={сlickOnClientsNC} //управлять отображением кнопок Добавить клиента и др
                    infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                    infoDark={infoDark} // давать ли информацию по абонементам Dark
                    admin={true} // проверка страница admin или нет (true - admin)
                    handleNameClient={сlickOnClientsNC}// пробросить из ClientInfo для WA
                    handlePhoneNumberClient={сlickOnClientsNC} // пробросить из ClientInfo для WA
                    backgroundColor={'clients-table_background'} // стиль фона
                />
            </>
        </div>
    )
}

export default WindowClientsControl;