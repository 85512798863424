import {  useState } from 'react';

function SearchFormForAddTraining({
    styleTextSearchForm, // стиль текста
    imgPhoneSearch, // картинка кнопки поиска по телефону
    imgNameSurnameSearch, // картинка кнопки поиска по телефону
    SearchByPhone,
    NameSurnameSearch,
}) {


    //стейт, который содержит значение инпута
    const [searchValue, setSearchValue] = useState(""); // добавляем состояние и функцию для его обновления

    function handleChangeValueNameAndSurname(event) { // обновляем состояние при изменении значения инпута
        setSearchValue(event.target.value)
    }

     const handleSearchByPhone = () => {
        SearchByPhone(searchValue)
    };

    const handleSearchByNameAndSurname = () => {
        NameSurnameSearch(searchValue)
    };


    return (<div className="search-form" >
        <div className={`search-form__container search-form-for-add-training`}>
            <div className="search-form__container-input">
                <img className="search-form__img search-form__input-button" src={imgPhoneSearch} alt="изибражение телефона" onClick={handleSearchByPhone} />
                <input className={`search-form__input ${styleTextSearchForm}`} type="text" value={searchValue} name="name" placeholder="Поиск" onChange={handleChangeValueNameAndSurname}></input>
                <img className="search-form__img search-form__input-button" src={imgNameSurnameSearch} alt="изображение стрелки" onClick={handleSearchByNameAndSurname} />
            </div>
        </div>
    </div >)
}

export default SearchFormForAddTraining;