
import { useState } from "react";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import Preloader from "../Preloader/Preloader";
import ClientsTable from "../ClientsTable/ClientsTable";
import Selector from "../Selector/Selector";

function HystoryTrainingList({ // Спиок тренировок в календаре ( отображение тренировок на главной админа и тренера) и возможность просмотреть кто записался на тренировку
    api, // передать АПИ студии из компонента студии в которое монтируется расписание
    trainings, // список тренировко
    textTitleColor, // цвет текста
    styleText,
    timeTableBackgroundColor, // цвет фона таблицы
    loaderTimeTable, // лоадер пока приходят тренировки с АПИ ( true или false )
    disabledButtonTrain, // работает ли клик на тренировку или нет, true - не работает, нужно для отображения компонента списка посещений
    timetableListTextStyle, // стиль для текста в истории посещений
    infoFlex,// давать ли информацию по абонементам Flex
    infoDark, // давать ли информацию по абонементам Dark
    isTrainerPage, // true - страница тренера
    trainingsGroupe, // массив тренировок групповых
    trainingsPersonalAndOthers, // массив персональных и других тренировок(кроме групповых)
    idTrainer, // айди тренера, если страница тренера(в TrainingList отображаются только его тренировки)
    backgroundButtonColor, // цвет фона кнопок
    client, // проверка есть ли клиент в БД, если нет, то не показывать инф об абонементе (true - клиент есть в БД)
    timetableWeekButtonStyle, // цвет фона кнопок дней недели
    titleTimeTable, // заголовок таблицы
    timetableButtonStyle, // цвет фона кнопок переключения недели
    idClient, // айди клиента
    previousWeekButtonImg, // изображение стрелки перекл дней недели
    nextMonthButtonImg, // изображение стрелки перекл дней недели
}
) {

    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
    const [isClientsTable, setIsClientsTable] = useState(false); // стейт для отображения списка записавшихся клиентов
    const [clients, setClients] = useState([]) // список клиентов на тренировке для отображения
    const [waitingList, setWaitingList] = useState([]) // список клиентов в листе ожидания
    const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    const [isButtonInfoTool, setButtonInfoTool] = useState(false); // отображать или нет кнопки в инфотуле
    const [idTrain, setIdTrain] = useState(''); // айди тренировки для записи во время открытия Инфотула

    //Календарь
    const [isChangeArrayTrains, setIsChangeArrayTrains] = useState(true); // true - отображать все тренировки,false -только те, гдет тренер ведет(актуально для страницы Тренера TrainerPage) 

    // Открыть список записавшихся на тренировку
    function openInfoToolGetVisits(trainid, time, formattedDate) {
        console.log(trainid)
        api.getTrainById(trainid)
            .then((train) => {
                setIsClientsTable(true);
                setClients(train.train.listOfVisits);
            }).catch((err) => { console.log(err) });
    }

    //функция для передачи в ClientTable, чтобы клик не срабатывал
    function сlickOnClients() {
        //заглушка - не удалять
    }

    //Переключать тренировки на все или на без групповых
    function handleIsChangeArrayTrains(state) {
        if (state) {
            setIsChangeArrayTrains(false);
        } else {
            setIsChangeArrayTrains(true);
        }

    }

    //Инфотул
    function closeInfoTool() {
        setIsInfoToolOpen(false);
    }

    //Отрисовать тренировки
    function renderTrainings(trainings, timeTableBackgroundColor, textTitleColor, openInfoToolGetVisits) {
        if (trainings.length < 1 || trainings.length === undefined) {
            return <p className={`timetable__text-no-record ${styleText}`}>Записи отсутсвуют</p>;
        } else {
            return trainings.map(train => {
                const date = new Date(train.dateOn);
                const dateTo = new Date(train.dateTo);

                const dateTrain = new Intl.DateTimeFormat('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' }).format(date);
                const timeTo = dateTo.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
                const time = date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });

                return (
                    <button
                        className={`timetable__list-element ${timeTableBackgroundColor}`}
                        id={train._id}
                        key={train._id}
                        onClick={() => { openInfoToolGetVisits(train._id, time, dateTrain) }}
                    >
                        <div className="timetable__element">
                            <p className={`timetable__text ${textTitleColor} ${styleText} timetable__element_dateTrain`}>{dateTrain}</p>
                        </div>
                        <div className="timetable__element">
                            <p className={`timetable__text ${styleText} ${textTitleColor}`}>{time} - {timeTo}</p>
                            <p className={`timetable__text ${textTitleColor} ${styleText}`}>{train.name}</p>
                            <p className={`timetable__text ${textTitleColor} ${styleText}`}>{train.trainer.name}</p>
                        </div>
                        <div className="timetable__element">
                            <p className={`timetable__text ${textTitleColor} ${styleText}`}>Заполненность {train.listOfVisits.length}</p>
                            <p className={`timetable__text ${textTitleColor} ${styleText}`}>{train.typeOfTraining}</p>
                        </div>
                    </button>
                );
            });
        }
    }


    return (
        <div className={`timetable ${timetableListTextStyle}`}>
            <InfoTooltip
                isOpen={isInfoToolOpen} text={isTextMassageInfoTool} isClose={closeInfoTool} showButton={isButtonInfoTool} backgoundColor={timeTableBackgroundColor}
            />
            <h2 className={`timetable__title ${textTitleColor} `}>Групповые тренировки</h2>
            {isTrainerPage ?
                <Selector
                    nameOne={'Общий список'}
                    handleOnClickOne={handleIsChangeArrayTrains} // переключать массив тренировок ( с групповыми или без)
                />
                : <></>}
            {/* ГРУППОВЫЕ ТРЕНИРОВКИ */}
            <div className="timetable__list">
                {loaderTimeTable ? // данные в процессе получения - отобразить лоадер
                    <Preloader /> :
                    renderTrainings(trainingsGroupe, timeTableBackgroundColor, textTitleColor, openInfoToolGetVisits)
                }
            </div>
            {/* 
             */}
            <h2 className={`timetable__title ${textTitleColor} `}>Персональные и другие</h2>
            <div className="timetable__list">
                {loaderTimeTable ? // данные в процессе получения - отобразить лоадер
                    <Preloader /> :
                    renderTrainings(trainingsPersonalAndOthers, timeTableBackgroundColor, textTitleColor, openInfoToolGetVisits)
                }
            </div>
            {/* Список клиентов, записанных на тренировку */}
            {isClientsTable ?
                <>
                    <h2 className={`timetable__title ${textTitleColor}`}>Список клиентов</h2>
                    <ClientsTable
                        fullInfoClient={false} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                        clients={clients} // список клиентов для отображения в таблице
                        handleActiveClientInfo={сlickOnClients} //управлять отображением кнопок Добавить клиента и др
                        infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                        infoDark={infoDark} // давать ли информацию по абонементам Dark
                        admin={true} // проверка страница admin или нет (true - admin)
                        handleNameClient={сlickOnClients}// пробросить из ClientInfo для WA
                        handlePhoneNumberClient={сlickOnClients} // пробросить из ClientInfo для WA
                        backgroundColor={'clients-table_background'} // стиль фона
                        styleText ={styleText}
                    />
                </>
                : <></>}
        </div >
    )
}

export default HystoryTrainingList;