import { useState } from 'react';

import imgNameSurnameSearch from './../../images/search-svgrepo-com.svg';
import imgPhoneSearch from './../../images/mobile-svgrepo-com.svg';

function SearchForm({
    handleSubmOnSearch, // сабмит 
    handleSearchType, // управлять поиском по номеру или Имени
    styleSearchForm, // стиль формы поиска
    styleTextSearchForm, // стиль текста
}) {

    //стейт, который содержит значение инпута
    const [searchValueNameAndSurname, setSearchValueNameAndSurname] = useState(""); // добавляем состояние и функцию для его обновления
    const [searchValue, setSearchValue] = useState(""); // добавляем состояние и функцию для его обновления

    function handleChangeValueNameAndSurname(event) { // обновляем состояние при изменении значения инпута
        setSearchValue(event.target.value)
    }

    const handleSearchByPhone = () => {
        handleSearchType("phone");
    };

    const handleSearchByNameAndSurname = () => {
        handleSearchType("nameAndSurname");
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleSubmOnSearch(searchValue)
    };

    return (

        <form className="search-form" onSubmit={handleSubmit}>
            <div className={`search-form__container ${styleSearchForm}`}>
                <div className="search-form__container-input">
                    <button
                        className="search-form__input-button search-form__input-button_on-phone"
                        onClick={handleSearchByPhone}
                    >
                        <img className="search-form__img" src={imgPhoneSearch} alt="изибражение телефона" />
                    </button>
                    <input className={`search-form__input ${styleTextSearchForm}`} type="text" value={searchValue} name="name" placeholder="Поиск" onChange={handleChangeValueNameAndSurname}></input>
                    <button
                        className={`search-form__input-button`}
                        onClick={handleSearchByNameAndSurname}
                    >
                        <img className="search-form__img" src={imgNameSurnameSearch} alt="изображение стрелки" />
                    </button>
                </div>

            </div>
        </form >
    )
}

export default SearchForm;