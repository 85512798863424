// История тренировок ( используется для показа списка проведенных за месяц тренировок конкретным тренером)
// TrainerPage --> this

import { useEffect, useState } from "react";

import HystoryTrainingList from "../HystoryTrainingList/HystoryTrainingList";

function TrainingHistory({
    idTrainer, // айди тренера
    isSuperAdmin, // страница суперадмина -true
    isHideListTrains, // показывать ли список тренировок, true - скрыть (необходимо для того, чтобы не показывать у суперадмина в статистике)
    api, // АПИ студии
    styleForm, // стиль формы выбора месяца
    colorBackground, // цвет фона
    styleText, // стиль текста
    textTitleColor, // цвет титульников
    infoPaymentsToTrainer, // данные по стоимости посещений для рассчета
    styleTitleForm, // стиль текста титульника формы
    colorButtonSubmit, // стиль кнопки сабмита
}) {

    const [month, setMonth] = useState('');
    const [monthNow, setMonthNow] = useState('');
    const [year, setYear] = useState(''); // Добавлено состояние для года
    const [yearNow, setYearNow] = useState('');
    const [yearPrevious, setYearPrevious] = useState('');
    const [yearsOptions, setYearsOptions] = useState([]); // опции в селект для выбора года у суперадмина
    const [monthsOptions, setMonthsOptions] = useState([]); // опции в селект для выбора месяца
    const [monthPrevious, setMonthPrevious] = useState('');
    const [trainings, setTrainings] = useState([]); // массив тренировок тренера на месяц
    //Количество посещений
    const [numberOfVisitsInGroups, setNumberOfVisitsInGroups] = useState(0); // количество посещений в группах
    const [numberOfVisitsInPersonalOne, setNumberOfVisitsInPersonalOne] = useState(0); // количество посещений в персональных-разовых
    const [numberOfVisitsInPersonalFour, setNumberOfVisitsInPersonalFour] = useState(0); // количество посещений в персональных-абонемент на 4 тренировк
    const [numberOfVisitsInPersonalEight, setNumberOfVisitsInPersonalEight] = useState(0); // количество посещений в персональных-абонемент на 8 тренировк
    const [numberOfVisitsInPersonalSplitOne, setNumberOfVisitsInPersonalSplitOne] = useState(0); // количество посещений в персональных-сплит-абонемент разовых
    const [numberOfVisitsInPersonalSplitFour, setNumberOfVisitsInPersonalSplitFour] = useState(0); // сплит-4
    const [numberOfVisitsInPersonalSplitEight, setNumberOfVisitsInPersonalSplitEight] = useState(0); // сплит -8
    //Оплаты тренерам
    const [paymentOfVisitsInGroups, setPaymentOfVisitsInGroups] = useState(0); // количество оплат в группах
    const [paymentOfVisitsInPersonalSplitOne, setPaymentOfVisitsInPersonalSplitOne] = useState(0); // количество оплат в персональных-сплит-абонемент разовых
    const [paymentOfVisitsInPersonalSplitFour, setPaymentOfVisitsInPersonalSplitFour] = useState(0); // сплит-4
    const [paymentOfVisitsInPersonalSplitEight, setPaymentOfVisitsInPersonalSplitEight] = useState(0); // сплит -8
    const [paymentOfVisitsInPersonalOne, setPaymentOfVisitsInPersonalOne] = useState(0); // количество оплат в персональных-разовых
    const [paymentOfVisitsInPersonalFour, setPaymentOfVisitsInPersonalFour] = useState(0); // количество оплат в персональных-абонемент на 4 тренировк
    const [paymentOfVisitsInPersonalEight, setPaymentOfVisitsInPersonalEight] = useState(0); // количество оплат в персональных-абонемент на 8 тренировк
    const [nameTrainer, setNameTrainer] = useState(''); // имя тренера
    const [surnameTrainer, setSurnameTrainer] = useState(''); // фамилия тренера
    const [trainingsGroupe, setTrainingsGroupe] = useState([]) // массив тренировок групповых
    const [trainingsPersonalAndOthers, setTrainingsPersonalAndOthers] = useState([]) // массив персональных и других тренировок(кроме групповых)

    function onSubmit(event) {
        event.preventDefault();
    }

    //Слушать изменение месяца
    function handleChangeMonth(event) {
        let month = event.target.value
        setMonth(month);
    }

    //Слушать изменения года
    function handleChangeYear(event) {
        let year = event.target.value;
        setYear(year);
    }

    // Установка текущего месяца и предыдущего месяца, а также года при загрузке
    useState(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
        setMonth(currentMonth); // установить текущий месяц при загрузке
        setMonthNow(currentMonth); // Текущий месяц

        const previousMonth = new Date();
        previousMonth.setMonth(previousMonth.getMonth() - 1);
        const previousMonthName = previousMonth.toLocaleString('default', { month: 'long' });
        setMonthPrevious(previousMonthName); // предыдущий месяц

        const currentYear = currentDate.getFullYear(); // Получение текущего года
        setYearNow(currentYear);

        const previousYear = previousMonth.getFullYear(); // Получение года предыдущего месяца
        setYearPrevious(previousYear);

    }, [])

    //Получить все тренировки тренера в течение указанного месяца
    useEffect(() => {
        if (idTrainer && month && year) {
            api.getAllTrainOfTrainerForMonth(idTrainer, month, year)
                .then((trainings) => {
                    setTrainings(trainings.trainings)
                })
        }
    }, [idTrainer, month, year, api]);

    //Наполнение информацией о количестве и видах тренировок
    useEffect(() => {
        // Отфильтровать массив тренировок по ключу typeOfTraining: групповая
        const filteredGroupeTrainings = trainings.filter(train => train.typeOfTraining === 'групповая');
        const sortedGroupeTrainings = filteredGroupeTrainings.sort((a, b) => new Date(a.dateOn) - new Date(b.dateOn)); // Отсортировать массив тренировок по датам от ближайшей к текущей дате
        setTrainingsGroupe(sortedGroupeTrainings); // Поместить отфильтрованные и отсортированные тренировки в стейт

        // Подсчет количества посещений на групповых тренировках
        let visitsCount = 0;
        sortedGroupeTrainings.forEach(train => {
            visitsCount += train.listOfVisits.length;
        });
        setNumberOfVisitsInGroups(visitsCount);

        // Отфильтровать оставшиеся тренировки (не групповые)
        const filteredPersonalAndOthersTrainings = trainings.filter(train => train.typeOfTraining !== 'групповая');
        const sortedPersonalAndOthersTrainings = filteredPersonalAndOthersTrainings.sort((a, b) => new Date(a.dateOn) - new Date(b.dateOn));  // Отсортировать оставшиеся тренировки по датам от ближайшей к текущей дате
        setTrainingsPersonalAndOthers(sortedPersonalAndOthersTrainings); // Поместить отфильтрованные и отсортированные тренировки в стейт

        // Подсчет количества посещений на персональных тренировках
        let visitsCountOne = 0;
        let visitsCountFour = 0;
        let visitsCountEight = 0;
        let visitsCountSplitOne = 0;
        let visitsCountSplitFour = 0;
        let visitsCountSplitEight = 0;
        sortedPersonalAndOthersTrainings.forEach(train => {
            if (train.typeOfTraining === 'персональная-разовая') {
                visitsCountOne += train.listOfVisits.length;
            } else if (train.typeOfTraining === 'персональная-4') {
                visitsCountFour += train.listOfVisits.length;
            } else if (train.typeOfTraining === 'персональная-8') {
                visitsCountEight += train.listOfVisits.length;
            } else if (train.typeOfTraining === 'персональная-сплит-разовая') {
                visitsCountSplitOne += train.listOfVisits.length;
            } else if (train.typeOfTraining === 'персональная-сплит-4') {
                visitsCountSplitFour += train.listOfVisits.length;
            } else if (train.typeOfTraining === 'персональная-сплит-8') {
                visitsCountSplitEight += train.listOfVisits.length;
            }
        });
        setNumberOfVisitsInPersonalOne(visitsCountOne);
        setNumberOfVisitsInPersonalFour(visitsCountFour);
        setNumberOfVisitsInPersonalEight(visitsCountEight);
        setNumberOfVisitsInPersonalSplitOne(visitsCountSplitOne);
        setNumberOfVisitsInPersonalSplitFour(visitsCountSplitFour);
        setNumberOfVisitsInPersonalSplitEight(visitsCountSplitEight);
    }, [trainings])

    //получить при первой отрисовке компонента имя тренера
    useEffect(() => {
        if (idTrainer) {
            api.getTrainerById(idTrainer)
                .then((trainer) => {
                    setNameTrainer(trainer.trainer.name);
                    setSurnameTrainer(trainer.trainer.surname);
                })
                .catch((error) => { console.log(error) });
        }
    }, [idTrainer, api])

    //Наполнить опции для селекта выбора года и месяцев у суперадмина
    useEffect(() => {
        //Год
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        setYear(currentYear); // первоначально текущий год
        setMonth(currentMonth); // первоначально текущий месяц
        const options = [];
        for (let i = 0; i < 5; i++) {
            const yearValue = currentYear - i;
            options.push(
                <option key={yearValue} value={yearValue}>
                    {yearValue}
                </option>
            );
        }
        setYearsOptions(options);

        //месяц
        const optionsMonths = [];
        for (let i = 0; i < 12; i++) {
            const month = new Date();
            month.setMonth(month.getMonth() - i);
            const monthName = month.toLocaleString('default', { month: 'long' });
            optionsMonths.push(
                <option value={monthName} key={monthName}>
                    {monthName}
                </option>
            );
        }
        setMonthsOptions(optionsMonths);
    }, []);


    //Получить данные для рассчета ЗП тренера(Только для суперадмина)
    useEffect(() => {

        let paymentVisitsGroups = 0;

        let paymentVisitsPersonalOne = 0;
        let paymentVisitsPersonalFour = 0;
        let paymentVisitsPersonalEight = 0

        let paymentVisitsPersonalSplitOne = 0;
        let paymentVisitsPersonalSplitFour = 0;
        let paymentVisitsPersonalSplitEight = 0;

        if (isSuperAdmin && infoPaymentsToTrainer !== undefined) {
            infoPaymentsToTrainer.forEach(payment => {
                if (payment.type === 'групповая') {
                    paymentVisitsGroups = Number(payment.payment) * Number(numberOfVisitsInGroups);
                } else if (payment.type === 'персональная-разовая') {
                    paymentVisitsPersonalOne = Number(payment.payment) * Number(numberOfVisitsInPersonalOne);
                } else if (payment.type === 'персональная-4') {
                    paymentVisitsPersonalFour = Number(payment.payment) * Number(numberOfVisitsInPersonalFour);
                } else if (payment.type === 'персональная-8') {
                    paymentVisitsPersonalEight = Number(payment.payment) * Number(numberOfVisitsInPersonalEight);
                } else if (payment.type === 'персональная-сплит-разовая') {
                    paymentVisitsPersonalSplitOne = Number(payment.payment) * Number(numberOfVisitsInPersonalSplitOne);
                } else if (payment.type === 'персональная-сплит-4') {
                    paymentVisitsPersonalSplitFour = Number(payment.payment) * Number(numberOfVisitsInPersonalSplitFour);
                } else if (payment.type === 'персональная-сплит-8') {
                    paymentVisitsPersonalSplitEight = Number(payment.payment) * Number(numberOfVisitsInPersonalSplitEight);
                }

            });
        }
        setPaymentOfVisitsInGroups(paymentVisitsGroups); // количество оплат в группах
        setPaymentOfVisitsInPersonalSplitOne(paymentVisitsPersonalOne); // количество оплат в персональных-сплит-абонемент разовых
        setPaymentOfVisitsInPersonalSplitFour(paymentVisitsPersonalFour); // сплит-4
        setPaymentOfVisitsInPersonalSplitEight(paymentVisitsPersonalEight); // сплит -8
        setPaymentOfVisitsInPersonalOne(paymentVisitsPersonalSplitOne); // количество оплат в персональных-разовых
        setPaymentOfVisitsInPersonalFour(paymentVisitsPersonalSplitFour); // количество оплат в персональных-абонемент на 4 тренировк
        setPaymentOfVisitsInPersonalEight(paymentVisitsPersonalSplitEight); // количество оплат в персональных-абонемент на 8 тренировк

    }, [
        isSuperAdmin,
        infoPaymentsToTrainer,
        numberOfVisitsInGroups,
        numberOfVisitsInPersonalSplitOne,
        numberOfVisitsInPersonalSplitFour,
        numberOfVisitsInPersonalSplitEight,
        numberOfVisitsInPersonalOne,
        numberOfVisitsInPersonalFour,
        numberOfVisitsInPersonalEight,
    ])

    return (
        <>
            <div className="timetable__list">

                {/* ФОРМА ВЫБОРА МЕСЯЦА И ТРЕНЕРА(ДЛЯ СУПЕРАДМИНА) */}
                <form className={`formadd__form formadd__training-history ${styleForm}`} onSubmit={onSubmit}>
                    {/* Выбор месяца */}
                    <div className="formadd__input-conainer">
                        <select
                            value={month}
                            onChange={handleChangeMonth}
                            className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                        >
                            {isSuperAdmin ?
                                monthsOptions
                                :
                                <>
                                    <option value={monthNow}>{monthNow}</option>
                                    <option value={monthPrevious}>{monthPrevious}</option>
                                </>
                            }
                        </select>
                        <span className={`formadd__text-error ${styleText}`} id="error-firstname">Выбор месяца</span>
                    </div>
                    {/* Выбор года */}
                    {isSuperAdmin ?
                        <div className="formadd__input-conainer">
                            <select
                                value={year}
                                onChange={handleChangeYear}
                                className={`${colorBackground}  formadd__input formadd__input_name_image formadd__input-select`}
                            >
                                {yearsOptions}
                            </select>
                            <span className={`formadd__text-error ${styleText}`} id="error-firstname">Выбор года</span>
                        </div>
                        : <></>}
                </form>
                {/* ИНФОРМАЦИЯ О ПОСЕЩЕНИЯХ */}
                {isSuperAdmin ?
                    < h3 className={`trainer-page__info ${textTitleColor}`}> {nameTrainer} {surnameTrainer} Посещения </h3 >
                    :
                    < h3 className={`trainer-page__info ${styleText}`}> {nameTrainer} {surnameTrainer}</h3 >}
                <div className='trainer-page__info-container'>
                    <div className='trainer-page__info-container-column'>
                        <p className={`trainer-page__info-text ${styleText}`}>Тип </p>
                        <p className={`trainer-page__info-text ${styleText}`}>в группах: </p>
                        <p className={`trainer-page__info-text ${styleText}`}>персональные-разовые: </p>
                        <p className={`trainer-page__info-text ${styleText}`}>персональные-4:</p>
                        <p className={`trainer-page__info-text ${styleText}`}>персональные-8:</p>
                        <p className={`trainer-page__info-text ${styleText}`}>сплит-разовые: </p>
                        <p className={`trainer-page__info-text ${styleText}`}>сплит-4:</p>
                        <p className={`trainer-page__info-text ${styleText}`}>сплит-8: </p>
                    </div>
                    <div className='trainer-page__info-container-column'>
                        <p className={`trainer-page__info-text trainer-page__info-text_align-center ${styleText}`}>Посещения</p>
                        <p className={`trainer-page__info-text trainer-page__info-text_align-center ${styleText}`}>{numberOfVisitsInGroups}</p>
                        <p className={`trainer-page__info-text trainer-page__info-text_align-center ${styleText}`}>{numberOfVisitsInPersonalOne}</p>
                        <p className={`trainer-page__info-text trainer-page__info-text_align-center ${styleText}`}>{numberOfVisitsInPersonalFour}</p>
                        <p className={`trainer-page__info-text trainer-page__info-text_align-center ${styleText}`}>{numberOfVisitsInPersonalEight}</p>
                        <p className={`trainer-page__info-text trainer-page__info-text_align-center ${styleText}`}>{numberOfVisitsInPersonalSplitOne}</p>
                        <p className={`trainer-page__info-text trainer-page__info-text_align-center ${styleText}`}>{numberOfVisitsInPersonalSplitFour}</p>
                        <p className={`trainer-page__info-text trainer-page__info-text_align-center ${styleText}`}>{numberOfVisitsInPersonalSplitEight}</p>
                    </div>
                    {isSuperAdmin && infoPaymentsToTrainer !== undefined ?
                        <div className='trainer-page__info-container-column'>
                            <p className={`trainer-page__info-text trainer-page__info-text_align-right ${styleText}`}>₽</p>
                            <p className={`trainer-page__info-text trainer-page__info-text_align-right ${styleText}`}>{paymentOfVisitsInGroups}</p>
                            <p className={`trainer-page__info-text trainer-page__info-text_align-right ${styleText}`}>{paymentOfVisitsInPersonalOne}</p>
                            <p className={`trainer-page__info-text trainer-page__info-text_align-right ${styleText}`}>{paymentOfVisitsInPersonalFour}</p>
                            <p className={`trainer-page__info-text trainer-page__info-text_align-right ${styleText}`}>{paymentOfVisitsInPersonalEight}</p>
                            <p className={`trainer-page__info-text trainer-page__info-text_align-right ${styleText}`}>{paymentOfVisitsInPersonalSplitOne}</p>
                            <p className={`trainer-page__info-text trainer-page__info-text_align-right ${styleText}`}>{paymentOfVisitsInPersonalSplitFour}</p>
                            <p className={`trainer-page__info-text trainer-page__info-text_align-right ${styleText}`}>{paymentOfVisitsInPersonalSplitEight}</p>
                        </div>
                        : <></>}
                </div>
                {/* СПИСОК ПРОВЕДЕННЫХ ТРЕНИРОВОК ЗА МЕСЯЦ */}
                {isHideListTrains && isSuperAdmin ? // не показывать если супер админ и передана опция скрыть
                    <></>
                    :
                    <HystoryTrainingList
                        api={api} // АПИ
                        trainingsGroupe={trainingsGroupe} // массив тренировок групповых
                        trainingsPersonalAndOthers={trainingsPersonalAndOthers} // массив персональных и других тренировок(кроме групповых)
                        styleText={styleText} // стиль текста
                        textTitleColor ={textTitleColor} // цвет титульников
                    />}
            </div>
        </>
    )
}

export default TrainingHistory;