//Информация о тренере и проведенных тренировках
//SuperAdmin --> this 
//Страница тренера студии --> this 

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import InfoTooltip from "../InfoTooltip/InfoTooltip"; // инфотул для отображения информации об ошибке
import TrainingCalendar from '../TrainingCalendar.js/TrainingCalendar'; // календарь
import TrainingHistory from '../TrainingHistory/TrainingHistory'; // история тренировок
import SignIn from '../SignIn/SignIn'; // Авторизация
import SearchForm from '../SearchForm/SearchForm'; // форма поиска клиентов
import LoadingPage from '../LoadingPage/LoadingPage'; // Страница загрузки во время авторизации
import ClientsTable from '../ClientsTable/ClientsTable'; // Таблица клиентов
import FormAddTrain from '../FormAddTrain/FormAddTrain'; // Форма добавления тренировки

import imgAddPersonal from '../../images/add-square-s.svg'

function TrainerPage(
    {
        backgroundColor, // цвет фона
        textHeaderColor, // цвет титульника
        styleHeaderBoarderColor, // цвет полоски в хедере
        api, // АПИ
        apiAuth, // Апи авторизации
        colorBackgroundForm, // цвет фона формы добавления
        styleFooter, // стиль футера
        styleText, // стиль текста
        textTitleColor, // цвет титульников
        previousWeekButtonImg, // изображение стрелки перекл дней недели
        nextMonthButtonImg, // изображение стрелки перекл дней недели
        infoFlex,// давать ли информацию по абонементам Flex
        infoDark, // давать ли информацию по абонементам Dark
        imgPersonalAccaunt, // картинка кнопки перехода в ЛК
        imgList, // картинка кнопки тренировок(списка)
        imgTimeTable, // картинка кнопки календаря
        isSuperAdmin, //Возможность добавлять группы только у суперадмина
        idTrainerFromSuperAdmin, // айди тренера из компонента суперадмина
        infoPaymentsToTrainer, // данные по стоимости посещений для рассчета
        styleCheckboxButton,// стиль свитчера
    }

) {

    //Авторизация
    const [isAuthTrainer, setIsAuthTrainer] = useState(false); // авторизация тренера
    const [isPreloaderAuthWaiting, setIsPreloaderAuthWaiting] = useState(false); // true - показывать загрузочную страницу, пока проверяется автоиизация
    // стейты управления отображения компонентов на странице
    const [isBigTrainingCalendar, setIsBigTrainingCalendar] = useState(true); // стейт для открытия большого календаря
    const [isListTraining, setListTraining] = useState(true); // календарь в виде списка или большого календаря
    //стейты, хранящие данные 
    const [idTrainer, setIdTrainer] = useState('') // айди тренера
    const [trainers, setTrainers] = useState([]) // объект тренера внутри массива, тк передается в форму добавления тренировки
    const [trains, setTrains] = useState([]); // массив тренировок на месяц вперед и неделю назад от текущей даты для календаря
    //инфотул
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
    const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    //Календарь
    const [isBigCalendar, setIsBigCalendar] = useState(false); // показывать большой календарь
    const [isChangeArrayTrains, setIsChangeArrayTrains] = useState(true); // true - отображать все тренировки,false - без групповых 
    //Форма добавления персональной тренировки
    const [isOpenFormAddPersonalForTrainer, setIsOpenFormAddClientOnPersonal] = useState(false);
    //Форма поиска
    const [isSearch, setIsSearch] = useState(false);
    const [searchType, setSearchType] = useState('');
    //История посещений
    const [isTrainingHistory, setIsTrainingHistory] = useState(false);
    //Результат поиска
    const [isClientsTableLoader, setIsClientsTableLoader] = useState(false); // лоадер
    const [сlients, setClients] = useState([]); // массив клиентов для отображения
    //Айди клиента, на которого кликнули в поиске
    const [idClient, setClientId] = useState('')
    //отрисовка изменений в расписании
    const [isChangeTimetable, setIsChangeTimetable] = useState(false);
    // Новая тренировка
    const [newTrain, setNewTrain] = useState({})// объект новой тренировки


    //Авторизация тренеров
    function handleSignInTrainer(data) {
        apiAuth.loginTrainer(data)
            .then(() => {
                setIsAuthTrainer(true);
                setTimeout(() => { setIsInfoToolOpen(false); }, 3000)
            }).catch((err) => {
                setIsAuthTrainer(false);
                setTextMassageInfoTool(`${err.message}`)
                setTimeout(() => { setIsInfoToolOpen(false); }, 3000)
            })
    }


    //Если страница тренера, то айди при получении информации о тренера
    //Проверить авторизацию тренера при отрисовке компонена
    useEffect(() => {
        if (!isSuperAdmin) {
            setIsPreloaderAuthWaiting(true); // показать страницу загрузки авторизации
            api.getSelfTrainer()
                .then((selfTrainer) => {
                    setIdTrainer(selfTrainer.user._id);
                    const trainer = [...trainers, selfTrainer.user]
                    setTrainers(trainer);
                    setIsAuthTrainer(true);
                    setIsPreloaderAuthWaiting(false); // спрятать страницу загрузки авторизации
                })
                .catch((error) => {
                    setIsAuthTrainer(false);
                    setIsPreloaderAuthWaiting(false); // спрятать страницу загрузки авторизации
                });
        }
    }, [])

    //Если суперадмин, то айди устанавливается прямой передачей 
    useEffect(() => {
        if (isSuperAdmin) {
            setIdTrainer(idTrainerFromSuperAdmin);
        }
    }, [idTrainerFromSuperAdmin, isSuperAdmin])


    //Переключать тренировки на все или на без групповых
    function handleIsChangeArrayTrains(state) {
        if (state) {
            setIsChangeArrayTrains(false);
        } else {
            setIsChangeArrayTrains(true);
        }
    }

    //Переключать отображение календаря на список и большой
    function handleClickOnSettingCalendar() {
        setIsBigTrainingCalendar(true); // открыть календарь(внутри либо список либо большой)
        setIsBigCalendar(false); // false - список
        setIsSearch(false);
        setIsTrainingHistory(false);
    }

    //КАЛЕНДАРЬ
    function openBigCalendar() {
        setIsBigTrainingCalendar(true); // открыть календарь(внутри либо список либо большой)
        setIsBigCalendar(true); // false - список
        setIsSearch(false);
        setIsTrainingHistory(false)
    }

    //КАЛЕНДАРЬ
    //получить тренировки на месяц вперед и неделю назад для отрисовки и отфильтровать их по тренеру(только персональные, других тренеров скрыть)
    useEffect(() => {
        let updateGroupTrains = [];
        let updateAllTrains = [];
        api.getTrainsForCalendar()
            .then((trains) => {
                const AllTrains = trains.trains;
                updateGroupTrains = trains.trains.filter(training => training.trainer === idTrainer && training.typeOfTraining === "групповая");
                // if (isChangeArrayTrains) { // отображать все тренировки
                //     setTrains(updateGroupTrains)
                // } else { // если только групповые
                //     setTrains(updateAllTrains);
                // }
                setTrains(AllTrains)
            })
            .catch((err) => { console.log(err) });
    }, [isChangeArrayTrains, isChangeTimetable, newTrain])

    //Личный кабинет тренера(открыть)
    function handleClickOnPersonalAccaunt() {
        setIsTrainingHistory(true);
        setIsBigTrainingCalendar(false);
        setIsSearch(false);
    }

    //Если монтирован в страницу суперадмина, то сразу личный кабинет без расписания
    useEffect(() => {
        if (isSuperAdmin) {
            setIsBigTrainingCalendar(false);
        }
    }, [isSuperAdmin])

    //ИНФОТУЛ

    function handleInfoToolClose() {
        setIsInfoToolOpen(false);
    }

    //закрывать инфотул через 3 секунды после открытия
    useEffect(() => {
        if (isInfoToolOpen) {
            setTimeout(() => {
                setIsInfoToolOpen(false);
                setTextMassageInfoTool('')
            }, 3000)
        }
    }, [isInfoToolOpen]);

    function handleClickClient(idClient) {
        setIsOpenFormAddClientOnPersonal(true);
        setClientId(idClient);
    }

    function openSearch() {
        setIsOpenFormAddClientOnPersonal(true);
        setIsBigTrainingCalendar(false);
        setIsTrainingHistory(false);
    }

    //Управление добавлением персоналок 

    function addPersonal(data, clientsOnPers) {

        if (data.typeTrain.includes('групповая')) {
            api.createTrain(data)
                .then((newTrain) => {
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool("Тренировка создана");
                    setNewTrain(newTrain.newTrain); // новая тренировка
                    // Создаем новый массив, который содержит все существующие тренировки и новую тренировку
                    //const updatedTrains = [...trains, newTrain.newTrain];
                    // Устанавливаем новый массив тренировок в стейт
                    //setTrains(updatedTrains);
                    setIsOpenFormAddClientOnPersonal(false);
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool("Успешно");
                })
                .catch((err) => {
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool(`Произошла ошибка ${err.message}`);
                })
        } else if (data.typeTrain.includes('персональная-сплит')) {

            let idClients = []
            clientsOnPers.forEach(client => {
                idClients.push(client._id)
            });

            api.createSplitTraining(data, idClients)
                .then(newTraining => {
                    // Создаем новый массив, который содержит все существующие тренировки и новую тренировку
                    //const updatedTrains = [...trains, newTraining.training];
                    // Устанавливаем новый массив тренировок в стейт
                    //setTrains(updatedTrains);
                    setNewTrain(newTraining.training);
                    setIsOpenFormAddClientOnPersonal(false);
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool("Успешно");

                }).catch(error => {
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool(error.message);
                });

        } else if (data.typeTrain.includes('персональная') && !data.typeTrain.includes('сплит')) {
            let idClient = clientsOnPers[0]._id
            api.createPersonalTraining(data, idClient)
                .then((training) => {
                    setNewTrain(training.training)
                    setIsOpenFormAddClientOnPersonal(false);
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool("Успешно");
                })
                .catch((error) => {
                    setIsInfoToolOpen(true);
                    setTextMassageInfoTool(error.message);
                })
        }
    }

    useEffect(() => { if (isChangeTimetable) { setIsChangeTimetable(false) } }, [isChangeTimetable])

    function openFormAddPersonal() {
        setIsOpenFormAddClientOnPersonal(true)
    }

    function closeFormAddPersonalForTrainer() {
        setIsOpenFormAddClientOnPersonal(false)
    }



    return (
        isPreloaderAuthWaiting ?
            <LoadingPage backgroundColor={backgroundColor} />
            :
            !isSuperAdmin && !isAuthTrainer ? // если страница тренера и тренер не авторизован
                <SignIn
                    onSubmit={handleSignInTrainer}
                    textMassageInfoTool={isTextMassageInfoTool} // информация для отображения ошибки в случае возникновения
                />
                :
                <div className={`admin-page ${backgroundColor}`}>
                    {/* ИНФОТУЛ */}
                    {isInfoToolOpen ?
                        < InfoTooltip
                            isOpen={isInfoToolOpen}
                            isClose={handleInfoToolClose}
                            text={isTextMassageInfoTool}
                            showButton={false}
                            backgoundColor={colorBackgroundForm} />
                        : <></>}
                    {/* ШАПКА */}
                    {!isSuperAdmin ? // если суперадмин, то шапку не добавлять
                        <header className={`admin-page__header`}>
                            <div className={`mainPage__link-container admin-page__header-link-container`}>
                                <Link to="/flex" className={`mainPage__link-page ${textHeaderColor} ${styleHeaderBoarderColor}`}>Flex Lab</Link>
                                <Link to="/dark" className={`mainPage__link-page mainPage__link-page_Dark`}>DARK</Link>
                            </div>
                            <p className={`mainPage__link-page__description trainer-page__role ${textHeaderColor}`}>TRENERSKAYA</p>
                        </header>
                        : <></>}
                    {/* ФОРМА ДОБАВЛЕНИЯ ПЕРСОНАЛКИ */}
                    {isOpenFormAddPersonalForTrainer ?
                        <FormAddTrain
                            idTrainer={idTrainer} // айди тренера
                            infoFlex={infoFlex}
                            infoDark={infoDark}
                            api={api}
                            isOpen={isOpenFormAddPersonalForTrainer}
                            isSuperAdmin={isSuperAdmin} //Возможность добавлять группы только у суперадмина
                            colorBackground={colorBackgroundForm} // цвет фона
                            isClose={closeFormAddPersonalForTrainer} // свернуть (функция)
                            onSubmit={addPersonal} // действия при сабмите
                            trainers={trainers} // массив тренеров для показа в инпуте при выборе тренировки
                            isInputPersonal={true} // не показывать на главной возможность создания персональных тренировок(только через AboutClientPage)
                            isInputGroupe={true} // групповые
                            isTypeSpace={infoDark} // выбор зала, true - добавить выбор
                            styleText={styleText}
                        />
                        : <></>}
                    {/* РАСПИСАНИЕ И БОЛЬШОЙ КАЛЕНДАРЬ (В расписании только тренировки тренера(фильтрация внутри в зависимости от стейта тренера), в больщом - общие) */}
                    {isBigTrainingCalendar ?
                        <TrainingCalendar
                            trainings={trains} // массив всех тренировок
                            isBig={isBigCalendar} // true - показывать большой календарь
                            //ДЛЯ СПИСКА 
                            infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                            infoDark={infoDark} // давать ли информацию по абонементам Dark
                            previousWeekButtonImg={previousWeekButtonImg} // изображение стрелки перекл дней недели
                            nextMonthButtonImg={nextMonthButtonImg} // изображение стрелки перекл дней недели
                            api={api} // передать АПИ студии из компонента студии в которое монтируется расписание
                            backgroundButtonColor={backgroundColor} // цвет фона кнопок
                            titleTimeTable={'Список тренировок'} // заголовок таблицы
                            timeTableBackgroundColor={backgroundColor} // цвет фона таблицы
                            setTrains={setTrains} // менять стейт массива тренировок в компоненте студии в которое монтируется расписание
                            client={true} // проверка есть ли клиент в БД, если нет, то не показывать инф об абонементе (true - клиент есть в БД)
                            handleIsChangeArrayTrains={handleIsChangeArrayTrains} // переключать массив тренировок ( с групповыми или без)
                            isTrainerPage={true} // true - страница тренера
                            idTrainer={idTrainer} // айди тренера, если страница тренера(в TrainingList отображаются только его тренировки)
                            styleText={styleText} // стиль текста
                            textTitleColor={textTitleColor} // цвет титульников
                            styleCheckboxButton={styleCheckboxButton}   // стиль свитчера
                            backgoundColorPage ={backgroundColor}
                        />
                        : <></>
                    }
                    {isTrainingHistory ?
                        <TrainingHistory
                            api={api} // апи студии
                            idTrainer={idTrainer} // айди тренера
                            styleText={styleText} // стиль текста
                            textTitleColor={textTitleColor} // цвет титульников
                            isSuperAdmin={isSuperAdmin} // true - страница суперадмина
                            infoPaymentsToTrainer={infoPaymentsToTrainer} // данные по стоимости посещений для рассчета
                        />
                        : <></>
                    }
                    {!isSuperAdmin ? // если суперадмин, то не показывать футер
                        <footer className={`page__footer ${styleFooter}`}>
                            {/* Менять календарь на большой или список */}
                            <button className={`admin-page__list-comand-button ${backgroundColor}`}
                                onClick={handleClickOnSettingCalendar}>
                                <img className='admin-page__button-img' src={imgList} alt='imgHomePage' />
                            </button>
                            <button
                                className={`admin-page__list-comand-button  ${backgroundColor}`}
                                onClick={openSearch}>
                                <img className='admin-page__button-img' src={imgAddPersonal} alt='imgAddPersonal' />
                            </button>
                            <button
                                className={`admin-page__list-comand-button admin-page__list-comand-button_personal ${backgroundColor}`}
                                onClick={handleClickOnPersonalAccaunt}>
                                <img className='admin-page__button-img' src={imgPersonalAccaunt} alt='imgPersonalAccaunt' />
                            </button>
                            < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                onClick={openBigCalendar}>
                                <img className='admin-page__button-img' src={imgTimeTable} alt='imgTimeTable' />
                            </button>
                        </footer >
                        : <></>
                    }
                </div >
    )
}

export default TrainerPage;