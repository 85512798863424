// AboutClientPage -> this component

import { useEffect, useState } from "react";

import FormAdd from "../FormAdd/FormAdd";
import SearchFormForAddTraining from "../SearchFormForAddTraining/SearchFormForAddTraining";
import ClientsTable from "../ClientsTable/ClientsTable";

import imgPhoneSearch from "../../images/mobile-svgrepo-com.svg"
import imgNameSurnameSearch from "../../images/search-svgrepo-com.svg"

function FormAddTrain({
    infoFlex,
    infoDark,
    isOpen,
    isClose,
    onSubmit, // действия при сабмите
    colorBackground, // цвет фона
    colorButtonSubmit, // стиль кнопки сабмита
    abonementClient, // данные для абонемента
    isCheckGroup, // свитчер для отслеживания
    trainers, // список тренеров для инпута
    isInputGroupe, // true - показывать возможность выбора групповых тренировок при создании 
    isInputPersonal, // true - показывать возможность выбора персональных тренировок при создании
    isTypeSpace, // нужно ли добавить инпут с выбором зала 
    api,
    styleText,
    isAboutClientPage, // true без выбора клиента
}) {

    // Стейты, в которых содержятся значения инпута и информационный текст
    const [nameTrain, setNameTrain] = useState('') // название тренировки
    const [trainer, setTrainer] = useState('') // тренер
    const [typeTrain, setTypeTrain] = useState('') // тип тренировки
    const [typeSpace, setTypeSpace] = useState('') // тип зала
    const [numberOfPeople, setNumberOfPeople] = useState('') // максимальное количество людей на тренировке
    const [repeatValue, setRepeatValue] = useState('') // количество повторов тренировок
    const [dateOn, setDateOn] = useState(''); // дата начала
    const [dateTimeOn, setDateTimeOn] = useState(''); // время начала
    const [dateTo, setDateTo] = useState(''); // до какого числа
    const [dateTimeTo, setDateTimeTo] = useState(''); // время окончания
    const [quantityVisitsOn, setQuantityVisitsOn] = useState(''); // сколько изначально было тренировок
    const [quantityVisits, setQuantityVisits] = useState(''); // сколько из них осталось
    const [validationMassegeNameTrain, setValidationMassegeNameTrain] = useState('Введите название тренировки');
    const [validationMassegeTrainer, setValidationMassegeTrainer] = useState('Укажите тренера')
    const [validationMassegeTypeTrain, setValidationMassegeTypeTrain] = useState('Выберете тип тренировки');
    const [validationMassegeTypeSpace, setValidationMassegeTypeSpace] = useState('Выберите тип Зала');
    const [validationMassegeDateOn, setValidationMassegeDateOn] = useState('Дата тренировки');
    const [validationMassegeDateTimeOn, setValidationMassegeDateTimeOn] = useState('Время начала тренировки');
    const [validationMassegeDateTimeTo, setValidationMassegeDateTimeTo] = useState('Время окончания тренировки');
    const [validationMessageNumberOfPeople, setValidationMessageNumberOfPeople] = useState('Введите max вместимость'); //
    const [validationMassegeSearch, setValidationMassegeSearch] = useState('Клиенты на тренировке')
    const [validationMassegeQuantityVisitsOn, setValidationMassegeQuantityVisitsOn] = useState('');
    const [validationMassegeQuantityVisits, setValidationMassegeQuantityVisits] = useState('');
    const [validationMessageRepeatValue, setValidationMessageRepeatValue] = useState('Количество повторов тренировки');
    // стейты управления
    const [isFormEdited, setIsFormEdited] = useState(true); // управлять кнопкой сабмита ( для редактироваия), чтобы отправить, только после изменения одного из полей минимум
    const [isSuperAdmin, setIsSuperAdmin] = useState(false); // суперадмин или нет
    //добавление клиентов на тренировки (перс и сплит)
    const [clientsOnPers, setClientsOnPers] = useState([]) // массив клиентов на перс или сплит
    const [findClients, setFindClients] = useState([]); // массив найденных клиентов для выбора
    const [selectedClient, setSelectedClient] = useState({}) // клиен, на котором произошел клик для добавления на тренировку 
    //Поиск клиента для добавления на тренировку
    const [isPlusClientsOnTraining, setIsPlusClientsOnTraining] = useState(false); // отображать или нет поиск
    const [textSearch, setTextSearch] = useState('Внесите клиентов');
    //msx вместимость
    const [maxCapacity, setMaxCapacity] = useState('50')

    function cleanInput() {
        // setDateOn('');
        // setDateTo('');
        // setQuantityVisitsOn('');
        // setQuantityVisits('');
        setValidationMassegeDateOn('Дата тренировки');
        setValidationMassegeDateTimeOn('Время начала тренировки');
        setValidationMassegeDateTimeTo('Время окончания тренировки');
    }

    useEffect(() => {
        //Название тренировки
        if (validationMassegeNameTrain === 'Введите название тренировки') {
            document.getElementById('error-nametraining').classList.add('formadd__text-error_show');
        } else {
            document.getElementById('error-nametraining').classList.remove('formadd__text-error_show');
        }
        //Тренер
        if (validationMassegeTrainer === 'Укажите тренера') {
            document.getElementById('error-trainer').classList.add('formadd__text-error_show');
        } else {
            document.getElementById('error-trainer').classList.remove('formadd__text-error_show');
        }

        //Тип тренировки
        if (validationMassegeTypeTrain !== 'Тип тренировки') {
            document.getElementById('error-typeoftraining').classList.add('formadd__text-error_show');
        } else {
            document.getElementById('error-typeoftraining').classList.remove('formadd__text-error_show');
        }
        //Тип зала
        if (isTypeSpace && setValidationMassegeTypeSpace !== 'Тип зала') {
            document.getElementById('error-typeofspace').classList.add('formadd__text-error_show');
        } else if (isTypeSpace && setValidationMassegeTypeSpace === 'Тип зала') {
            document.getElementById('error-typeofspace').classList.remove('formadd__text-error_show');
        }
        //Вместимость
        if (validationMessageNumberOfPeople !== 'max вместимость') {
            document.getElementById('error-maxcapacity').classList.add('formadd__text-error_show');
        } else {
            document.getElementById('error-maxcapacity').classList.remove('formadd__text-error_show');
        }
        //Количество повторов 
        if (validationMessageRepeatValue !== 'Количество повторов тренировки') {
            document.getElementById('error-repeat-value').classList.add('formadd__text-error_show');
        } else {
            document.getElementById('error-repeat-value').classList.remove('formadd__text-error_show');
        }
        //Время начала тренировки 
        if (validationMassegeDateTimeOn !== 'Время начала тренировки') {
            document.getElementById('error-timeon').classList.add('formadd__text-error_show');
        } else {
            document.getElementById('error-timeon').classList.remove('formadd__text-error_show');
        }
        //Время окончание тренировки
        if (validationMassegeDateTimeTo !== 'Время окончания тренировки') {
            document.getElementById('error-timeto').classList.add('formadd__text-error_show');
        } else {
            document.getElementById('error-timeto').classList.remove('formadd__text-error_show');
        }
        //Дата тренировки
        if (validationMassegeDateOn !== 'Дата тренировки') {
            document.getElementById('error-dateon').classList.add('formadd__text-error_show');
        } else {
            document.getElementById('error-timeto').classList.remove('formadd__text-error_show');
        }

    }, [
        validationMassegeNameTrain,
        validationMassegeTrainer,
        validationMessageNumberOfPeople,
        validationMessageRepeatValue,
        validationMassegeDateTimeOn,
        validationMassegeDateOn,
        validationMassegeDateTimeTo,
        validationMassegeTypeSpace,
        nameTrain,
        trainer,
        typeTrain,
        numberOfPeople,
        dateOn,
        dateTimeOn,
        dateTimeTo,
    ])

    //передать данные  из инпутов
    function handleChangeNameTrain(e) { // слушать изменение названия тренировки
        setNameTrain(e.target.value);
        if (e.target.validationMessage === '') {
            setValidationMassegeNameTrain('название тренировки');
            if (document.getElementById('error-nametraining').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-nametraining').classList.remove('formadd__text-error_show');
            }
        } else {
            setValidationMassegeNameTrain(e.target.validationMessage);
            document.getElementById('error-nametraining').classList.add('formadd__text-error_show');
        }
    }

    function handleChangeTrainType(e) { // слушать изменение типа тренировки
        setTypeTrain(e.target.value);
        if (e.target.validationMessage === '') {
            setValidationMassegeTypeTrain('Тип тренировки');
            if (document.getElementById('error-typeoftraining').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-typeoftraining').classList.remove('formadd__text-error_show');
            }
        } else {
            setValidationMassegeTypeTrain(e.target.validationMessage);
            document.getElementById('error-typeoftraining').classList.add('formadd__text-error_show');
        }
    }

    function handleChangeDateOn(e) { // слушать изменение даты тренировки
        setDateOn(e.target.value);
        if (e.target.validationMessage === '') {
            setValidationMassegeDateOn('Дата тренировки');
            if (document.getElementById('error-dateon').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-dateon').classList.remove('formadd__text-error_show');
            }
        } else {
            setValidationMassegeDateOn(e.target.validationMessage);
            document.getElementById('error-dateon').classList.add('formadd__text-error_show');
        }
    }

    function handleChangeDateTimeOn(e) { // слушать изменение начала времени тренировки
        setDateTimeOn(e.target.value);
        if (e.target.validationMessage === '') {
            setValidationMassegeDateTimeOn('Время начала тренировки');
            if (document.getElementById('error-timeon').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-timeon').classList.remove('formadd__text-error_show');
            }
        } else {
            setValidationMassegeDateTimeOn(e.target.validationMessage);
            document.getElementById('error-timeon').classList.add('formadd__text-error_show');
        }
    }

    function handleChangeDateTimeTo(e) { // слушать изменение окончания времени тренировки
        setDateTimeTo(e.target.value);
        if (e.target.validationMessage === '') {
            setValidationMassegeDateTimeTo('Время окончания тренировки');
            if (document.getElementById('error-timeto').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-timeto').classList.remove('formadd__text-error_show');
            }
        } else {
            setValidationMassegeDateTimeTo(e.target.validationMessage);
            document.getElementById('error-timeto').classList.add('formadd__text-error_show');
        }
    }

    function handleChangeNumberOfPeople(e) { // слушать изменение количества max посещений
        setNumberOfPeople(e.target.value);
        if (e.target.validationMessage === '') {
            setValidationMessageNumberOfPeople('max вместимость');
            if (document.getElementById('error-maxcapacity').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-maxcapacity').classList.remove('formadd__text-error_show');
            }
        } else {
            setValidationMessageNumberOfPeople(e.target.validationMessage);
            document.getElementById('error-maxcapacity').classList.add('formadd__text-error_show');
        }
    }

    function handleChangeRepeatValue(e) { // слушать изменение повторений
        setRepeatValue(e.target.value);
        if (e.target.validationMessage === '') {
            setValidationMessageRepeatValue('Количество повторов тренировки');
            if (document.getElementById('error-repeat-value').classList.contains('formadd__text-error_show')) {
                document.getElementById('error-repeat-value').classList.remove('formadd__text-error_show');
            }
        } else {
            setValidationMessageRepeatValue(e.target.validationMessage);
            document.getElementById('error-repeat-value').classList.add('formadd__text-error_show');
        }
    }

    function handleChangeTrainer(e) { // слушать изменение выбранного тренера для тренировки
        setTrainer(e.target.value);
        if (e.target.value === '') {
            setValidationMassegeTrainer('Укажите тренера')
        } else {
            setValidationMassegeTrainer('Тренер')
        }
    }

    function handleChangeTypeSpace(e) { // слушать изменения выбора зала
        setTypeSpace(e.target.value);
        if (e.target.value === '') {
            setValidationMassegeTypeSpace('Тип зала')
        } else {
            setValidationMassegeTrainer('Укажите зал')
        }
    }

    //Автоматическая проставка количеств и повторов
    useEffect(() => {
        if (typeTrain.includes("персональная") && !typeTrain.includes("сплит")) {
            setNumberOfPeople(1);
            setMaxCapacity("1");
            setRepeatValue("не повторять");
            setValidationMessageNumberOfPeople('max вместимость');
        } else if (typeTrain.includes('сплит')) {
            setNumberOfPeople(2);
            setMaxCapacity("5");
            setRepeatValue("не повторять");
            setValidationMessageNumberOfPeople('max вместимость');
        } else if (typeTrain.includes('групповая')) {
            if (infoFlex) {
                setNumberOfPeople(8);
                setMaxCapacity("50");
                setValidationMessageNumberOfPeople('max вместимость');
            }
            if (infoDark) {
                setNumberOfPeople(20);
                setMaxCapacity("50");
                setValidationMessageNumberOfPeople('max вместимость');
            }
        }
    }, [typeTrain])

    //Автоматическое заполнение имени тренировки
    useEffect(() => {

        let name = '';

        if (typeTrain.includes('персональная') && !typeTrain.includes('сплит')) {
            name += "Перс "
        } else if (typeTrain.includes('сплит')) {
            name += "Сплит "
        }

        if (clientsOnPers.length > 0) {
            clientsOnPers.forEach((client) => {
                let surnameClient = client.surname + " " + client.name[0]
                name += " " + surnameClient
            })
        }

        setNameTrain(name)

    }, [typeTrain, clientsOnPers])

    //Передать данные = отправка на сервер 
    function handleSubmit(e) {
        e.preventDefault();

        // Создание объекта даты и времени начала и окончания
        const dateOnMoment = new Date(`${dateOn} ${dateTimeOn}`);
        const dateToMoment = new Date(`${dateOn} ${dateTimeTo}`);

        let data = {
            nameTrain,
            typeTrain,
            trainer,
            capacity: numberOfPeople,
            dateOn: dateOnMoment, // Дата и время начала
            dateTo: dateToMoment, // Дата и время окончания
            repeatValue,
        };
        if (isTypeSpace) { // если необходимо добавить зал
            data.typeSpace = typeSpace;
        }

        onSubmit(data, clientsOnPers);
    }


    //Свернуть форму
    function handleCloseForm() {

    }

    //очистить инпуты после сабмита
    useEffect(() => {
        cleanInput() // сбросить значения инпута
        setValidationMassegeQuantityVisits('Ставится автоматически, проставлять не нужно');
    }, [])

    //Проставить изначальное значение даты и времени
    useEffect(() => {

        //Дата
        const today = new Date();
        const formatter = new Intl.DateTimeFormat('en-CA', { dateStyle: 'short' });
        const formattedDate = formatter.format(today);

        setDateOn(formattedDate);
        setDateTo(formattedDate);

        //Время

        setDateTimeOn("18:00")
        setDateTimeTo("19:00")

    }, []);

    // Функция для получения количества минут из строки времени в формате "HH:mm" для валидации
    function getTimeInMinutes(time) {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    }

    useEffect(() => { // валидация формы
        const dateTimeOnIsValid = getTimeInMinutes(dateTimeOn) <= getTimeInMinutes(dateTimeTo); // дата начала тренировки меньше времени окончания

        const fields =
            [
                nameTrain,
                trainer,
                typeTrain,
                numberOfPeople,
                dateOn,
                dateTimeOn,
                dateTimeTo,
            ]

        if (infoDark) {
            fields.push(typeSpace)
        }

        const allFieldsFilled = fields.every((field) => field !== ''); // значения инпутов не пустые, возвращает true 
        if (dateTimeOnIsValid && allFieldsFilled) {
            console.log()
            setIsFormEdited(false);
        } else {
            setIsFormEdited(true);
        }
    }, [
        nameTrain,
        trainer,
        typeTrain,
        numberOfPeople,
        repeatValue,
        dateOn,
        dateTimeOn,
        dateTimeTo,
        typeSpace,
        clientsOnPers,
    ]);

    //Добавление клиентов на тренировку(только перс и сплит)---------------------------------------------
    useEffect(() => {
        if (typeTrain.includes('персональная')) {
            setIsPlusClientsOnTraining(true)
        } else {
            setIsPlusClientsOnTraining(false)
        }
    }, [typeTrain])

    function сlickOnClients(idClient, client) {
        const idCheck = clientsOnPers.some((item) => item._id === client._id);
        setSelectedClient(client)
        if (!idCheck) {
            if (Number(numberOfPeople) > clientsOnPers.length) {
                setClientsOnPers([...clientsOnPers, client]);
                setTextSearch('Клиенты')
            } else {
                setTextSearch('Превышена max вместимость')
            }
        } else {
            setTextSearch('Клиент уже добавлен на тренировку')
        }
    }

    function сlickOnClientsNC() {
        //заглушка
    }

    //Работа с записавшимися клиентами на тренировку ----------------------------------------------
    function сlickOnVisitsClients(idClient, client) {
        const updateClientsOnPers = clientsOnPers.filter((clientOnPers) => clientOnPers._id !== client._id)
        setClientsOnPers(updateClientsOnPers);
    }

    //SearchForm---------SearchForm---------SearchForm---------SearchForm---------SearchForm---------SearchForm---------SearchForm
    // управлять поиском по номеру или Имени

    //Поиск по номеру телефона
    function searchByPhoneNumber(value) {
        if (value.length > 0) {
            setTextSearch('Результат поиска');
            const number = +value; // Преобразовать в число

            // если поиск по клиентам
            if (value.length <= 11 && !isNaN(number)) {
                api
                    .getClientForNumber(number)
                    .then((res) => {
                        let Clients = res.clients;

                        if (Clients.length > 0) {
                            setFindClients(Clients);
                        } else {
                            setValidationMassegeSearch(
                                "Клиент с такими данными не найден, проверьте параметры ввода"
                            );
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                setValidationMassegeSearch("Пожалуйста, введите корректный номер телефона.");
            }
        } else {
            setTextSearch('Введите символы для поиска');
        }

    }

    //Главная функция поиска
    function searchByNameAndSurname(value) {

        if (value.length > 0) {
            setTextSearch('Результат поиска');
            let name;
            let surname;

            if (value.includes(" ")) {
                [surname, name] = value.split(" ");
                searchByFullName(surname, name);
            } else {
                searchByName(value);
            }
        } else {
            setTextSearch('Введите символы для поиска');
        }
    }

    function searchByFullName(surname, name) {

        api
            .getClientForName(surname, name)
            .then((res) => {
                let Clients = res.clients;
                if (Clients.length > 0) {
                    setFindClients(Clients);
                } else {
                    // Проверяем не перепутаны ли местами фамилия и имя
                    api
                        .getClientForName(name, surname)
                        .then((res) => {
                            let Clients = res.clients;
                            if (Clients.length > 0) {
                                setFindClients(Clients);
                            } else {
                                setValidationMassegeSearch(
                                    "Клиент с такими данными не найден, проверьте параметры ввода"
                                );
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    function searchByName(name) {
        api
            .getClientForName(name)
            .then((res) => {

                let Clients = res.clients;
                if (Clients.length > 0) {
                    setFindClients(Clients);
                } else {
                    searchBySurname(name);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function searchBySurname(surname) {

        api
            .getClientForName("", surname)
            .then((res) => {
                let Clients = res.clients;
                if (Clients.length > 0) {
                    console.log(Clients);
                    setFindClients(Clients);
                } else {
                    setValidationMassegeSearch(
                        "Клиент с такими данными не найден, проверьте параметры ввода"
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }


    return (
        <FormAdd
            onSubmit={handleSubmit} // сабмит
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={isClose} // клик на кнопку для сворачивания
            buttonCloseDisable={false} // отключить кнопку закрытия
            colorButtonSubmit={colorButtonSubmit} // стиль кнопки сабмита
            colorBackground={colorBackground} // управление цветом формы и инпутов
            text={'Добавить тренировку'} // заголовок формы
            textButtonSubmit={'Добавить'} // текст кнопки сабмита
            validationOptions={isFormEdited} // управлять кнопкой сабмита в зависимости от того, были заполненны поля или нет
            children={
                <>
                    {/* Название тренировки */}
                    <div className="formadd__input-conainer">
                        <input value={nameTrain}
                            type="string"
                            placeholder="Название тренировки"
                            className={`${colorBackground} formadd__input formadd__input_name_image`}
                            onChange={handleChangeNameTrain}
                            minLength="5" maxLength="30"
                        />
                        <span className="formadd__text-error" id="error-nametraining">{validationMassegeNameTrain}</span>
                    </div>
                    {/* Тип тренировки */}
                    <div className="formadd__input-conainer">
                        <select
                            value={typeTrain}
                            onChange={handleChangeTrainType}
                            className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                        >
                            <option value="">Выберите Тип Тренировки</option> {/* Добавить пустой вариант */}
                            {isInputPersonal ? // если персональные
                                <>
                                    <option value="персональная">Персональная абонемент</option>
                                    <option value="персональная-разовая">Персональная разовая</option>
                                    <option value="персональная-сплит">Cплит абонемент</option>
                                    <option value="персональная-сплит-разовая">Cплит разовая</option>
                                </>
                                : <></>
                            }
                        </select>
                        <span className="formadd__text-error" id="error-typeoftraining">{validationMassegeTypeTrain}</span>
                    </div>
                    {/* Максимальное количество людей на тренировке */}
                    <div className="formadd__input-conainer">
                        <input
                            type="number"
                            placeholder="Вместимость"
                            className={`${colorBackground} formadd__input formadd__input_name_image`}
                            value={numberOfPeople}
                            onChange={handleChangeNumberOfPeople}
                            min="1"
                            max={maxCapacity}
                        />
                        <span className="formadd__text-error " id="error-maxcapacity">
                            {validationMessageNumberOfPeople}
                        </span>
                    </div>
                    {/* Тренер */}
                    <div className="formadd__input-conainer">
                        <select
                            value={trainer}
                            onChange={handleChangeTrainer}
                            className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                        >
                            {/* <option value="">Выберите Тренера</option> Добавить пустой вариант */}
                            {trainers !== undefined ?
                                trainers.map((trainer) => {
                                    return <option value={trainer._id} key={trainer._id}>{trainer.name} {trainer.surname}</option>
                                })
                                : <></>
                            }
                            {/* <option value="аренда">Аренда</option>
                        <option value="Самотрен">Самотрен</option> */}
                        </select>
                        <span className="formadd__text-error" id="error-trainer">{validationMassegeTrainer}</span>
                    </div>
                    {/* Тип зала */}
                    {isTypeSpace ?
                        <div className="formadd__input-conainer">
                            <select
                                value={typeSpace}
                                onChange={handleChangeTypeSpace}
                                className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                            >
                                <option value="">Выберите Зал</option> {/* Добавить пустой вариант */}
                                <>
                                    <option className="formadd__select-option" value="большой">Большой</option>
                                    <option value="малый" className="formadd__select-option">Малый</option>
                                </>
                            </select>
                            <span className="formadd__text-error" id="error-typeofspace">{validationMassegeTypeSpace}</span>
                        </div>
                        : <></>}
                    {/* Выбор количества повторов */}
                    <div className="formadd__input-conainer">
                        <select
                            value={repeatValue}
                            onChange={handleChangeRepeatValue}
                            className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                        >
                            <option value="">Выберите повторы</option> {/* Добавить пустой вариант */}
                            <option className="formadd__select-option" value="не повторять">не повторять</option>
                            {isPlusClientsOnTraining ? <></>
                                : <option className="formadd__select-option" value="каждую неделю">каждую неделю</option>}
                        </select>
                        <span className="formadd__text-error" id="error-repeat-value">{validationMessageRepeatValue}</span>
                    </div>
                    {/* Дата тренировки */}
                    <div className="formadd__input-conainer">
                        <p className="formadd__input-text">Дата</p>
                        <input value={dateOn} type="date" placeholder="Дата тренировки" className={`${colorBackground} formadd__input formadd__input-time-and-date formadd__input_name_image`}
                            name="name-client" id="name-client" minLength="2" maxLength="30" onChange={handleChangeDateOn} required />
                        <span className="formadd__text-error" id="error-dateon">{validationMassegeDateOn}</span>
                    </div>
                    {/* Время начала */}
                    <div className="formadd__input-conainer">
                        <p className="formadd__input-text">Начало</p>
                        <input
                            type="time"
                            name="hours"
                            placeholder="Время начала"
                            className={`${colorBackground} formadd__input formadd__input-time-and-date formadd__input_name_image`}
                            onChange={handleChangeDateTimeOn}
                            value={dateTimeOn}
                        />
                        <span className="formadd__text-error" id="error-timeon">{validationMassegeDateTimeOn}</span>
                    </div>
                    {/* Время окончания */}
                    <div className="formadd__input-conainer">
                        <p className="formadd__input-text">Окончание</p>
                        <input
                            type="time"
                            name="hours"
                            placeholder="Время окончания"
                            className={`${colorBackground} formadd__input formadd__input-time-and-date formadd__input_name_image`}
                            onChange={handleChangeDateTimeTo}
                            value={dateTimeTo}
                        />
                    </div>
                    <span className="formadd__text-error" id="error-timeto">{validationMassegeDateTimeTo}</span>
                    {/* Клиенты */}
                    {isPlusClientsOnTraining && isAboutClientPage !== true ?
                        <div className="formadd__input-conainer">
                            {/* <div
                                value={typeTrain}
                                onChange={handleChangeTrainType}
                                className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                            > */}
                            {/* Добавить пустой вариант */}
                            {/* <option value="">Выберите Клиентов</option> 
                            </div>
                            <span className="formadd__text-error" id="error-typeoftraining">{validationMassegeSearch}</span> */}
                            {/* СПИСОК КЛИЕНТОВ ДЛЯ ЗАПИСИ */}
                            {/* <h3 className={`formadd__title-clients ${styleText}`}>{textSearch}</h3> */}
                            <SearchFormForAddTraining
                                SearchByPhone={searchByPhoneNumber}
                                NameSurnameSearch={searchByNameAndSurname}
                                styleTextSearchForm={styleText} // стиль текста
                                imgPhoneSearch={imgPhoneSearch} // картинка кнопки поиска по телефону
                                imgNameSurnameSearch={imgNameSurnameSearch} // картинка кнопки поиска по телефону
                            />
                            <h3 className={`formadd__title-clients ${styleText}`}>{textSearch}</h3>
                            <ClientsTable
                                fullInfoClient={true} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                                clients={findClients} // список клиентов для отображения в таблице
                                handleActiveClientInfo={сlickOnClients} //управлять отображением кнопок Добавить клиента и др
                                infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                                infoDark={infoDark} // давать ли информацию по абонементам Dark
                                admin={true} // проверка страница admin или нет (true - admin)
                                handleNameClient={сlickOnClientsNC}// пробросить из ClientInfo для WA
                                handlePhoneNumberClient={сlickOnClientsNC} // пробросить из ClientInfo для WA
                                backgroundColor={'clients-table_background'} // стиль фона
                                styleWidth={'clients-info__add-training'}
                            />
                            {/* СПИСОК ЗАПИСАННЫХ КЛИЕНТОВ */}
                            {clientsOnPers.length > 0 ?
                                <h3 className={`formadd__title-clients ${styleText}`}>{"Список записанных клиентов"}</h3>
                                : <></>}
                            <ClientsTable
                                fullInfoClient={false} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                                clients={clientsOnPers} // список клиентов для отображения в таблице
                                handleActiveClientInfo={сlickOnVisitsClients} //управлять отображением кнопок Добавить клиента и др
                                infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                                infoDark={infoDark} // давать ли информацию по абонементам Dark
                                admin={true} // проверка страница admin или нет (true - admin)
                                handleNameClient={сlickOnClientsNC}// пробросить из ClientInfo для WA
                                handlePhoneNumberClient={сlickOnClientsNC} // пробросить из ClientInfo для WA
                                backgroundColor={'clients-table_background'} // стиль фона
                                styleWidth={'clients-info__add-training'}
                            />
                        </div>
                        : <></>}
                </>
            }
        />
    )
}

export default FormAddTrain;