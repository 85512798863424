//TrainingList --> this

import { useEffect } from "react";
import { useZxing } from "react-zxing";

import Preloader from "../Preloader/Preloader";

function QRCodeReader({
    handleIdClient,
    isLoaderAPI,
    text,
    isButtons,
    handleClickOne,
    handleClickTwo,
    handleClickThree,
    isInfoMessageText,
    handleClickClose,
}) {

    const { ref } = useZxing({
        onResult(result) {
            handleIdClient(result.getText());
        },
    });

    useEffect(() => {

        if (isInfoMessageText || isButtons || isLoaderAPI) {
            ref.current.pause(); // Остановка видео
        } else if (ref.current.paused) {
            ref.current.play().catch((error) => {
                // Обработка ошибки play() запроса
                console.log("Error playing video:", error);
            }); // Запуск видео
        }

    }, [isInfoMessageText, isButtons, isLoaderAPI, ref]);

    return (
        <>
            <div className='qr-reader'>
                <div className='qr-reader__container'>
                    <button className="qr-reader__close-button" type="button" onClick={handleClickClose} />
                    <p className="qr-reader__text">{text}</p>
                    {isLoaderAPI ?
                        <Preloader />
                        :
                        isButtons ?
                            <div className="qr-reader__container-buttons">
                                <button className="qr-reader__button" onClick={handleClickOne}>Да</button>
                                <button className="qr-reader__button" onClick={handleClickTwo}>Нет</button>
                            </div>
                            :
                            isInfoMessageText ?
                                <button className="qr-reader__button" onClick={handleClickThree}>Продолжить</button>
                                :
                                <></>
                    }
                    <video ref={ref} style={{ width: '100%' }} />
                </div>
            </div >
        </>
    );
};

export default QRCodeReader;
