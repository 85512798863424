import React, { useState, useEffect } from 'react';
import ClientInfo from '../ClientInfo/ClientInfo';
// AdminPage -> this

function ClientsTable({
    clients, // список клиентов для отображения в таблиц
    backgroundColor, // цвет фона
    styleText, // стиль текста
    handleActiveClientInfo, //управлять отображением кнопок Добавить клиента и др
    infoFlex, // давать ли информацию по абонементам Flex
    infoDark, // давать ли информацию по абонементам Dark
    admin, // проверка страница admin или нет (true - admin)
    handleNameClient,// пробросить из ClientInfo для WA 
    handlePhoneNumberClient, // пробросить из ClientInfo для WA
    fullInfoClient, // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
    styleWidth,
}) {

    const [searchType, setSearchType] = useState("phone"); // поиск по телефону или по Имени и Фамилии
    // стейты содержащие данные
    // const [isClients, setIsClients] = useState([]); // массив клиентов

    // ОБЩИЕ функции 
    function sortClients(clients) { // отсортировать клиентов по алфавиту
        return clients
        // .sort((a, b) => a.surname.localeCompare(b.surname, 'ru'));
    }

    // открыть страницу инф о клиенте и передать в стейт его айди для поиска инф
    function handleOpenClient(idClient, nameClient, phoneNumberClient, client) {
        handleActiveClientInfo(idClient, client); // передать айди в AdminPage и далее в AboutClientPage
        handleNameClient(nameClient);
        handlePhoneNumberClient(phoneNumberClient);
    }

    // управлять поиском по номеру или Имени
    function handleSearchType(type) {
        setSearchType(type)
    }

    return (
        <div className="timetable">

            {/* СПИСОК КЛИЕНТОВ + ИНПУТ ПОИСКА КЛИЕНТОВ(ЕСЛИ НЕ БЫЛО КЛИКА НА КОНКРЕТНОМ КЛИЕНТЕ) */}

            {/* СПИСОК КЛИЕНТОВ */}
            <div className="timetable__list">
                {clients !== undefined ?
                    clients.map(client => {
                        let nameClient = client.name;
                        let surnameClient = client.surname;
                        let phoneNumberClient = client.phoneNumber;
                        let abonementClientFlexGroupe = client.abonementFlexGroupe;
                        let abonementClientFlexPersonal = client.abonementFlexPersonal;
                        let abonementClientDarkGroupe = client.abonementDarkGroupe;
                        let abonementClientDarkPersonal = client.abonementDarkPersonal;
                        let discount = client.abonementDiscount;
                        return <div
                            className={`timetable__list-element ${backgroundColor}`}
                            id={client._id}
                            key={client._id}
                            onClick={() => { handleOpenClient(client._id, nameClient, phoneNumberClient, client) }} // клик на клиента в списке
                        >
                            <ClientInfo
                                fullInfoClient={fullInfoClient} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                                nameClient={nameClient}
                                phoneNumberClient={phoneNumberClient}
                                surnameClient={surnameClient}
                                backgroundColor={backgroundColor}
                                abonementClientFlexGroupe={abonementClientFlexGroupe}
                                abonementClientFlexPersonal={abonementClientFlexPersonal}
                                abonementClientDarkGroupe={abonementClientDarkGroupe}
                                abonementClientDarkPersonal={abonementClientDarkPersonal}
                                infoFlex={infoFlex} // давать ли информацию по абонементам Flex
                                infoDark={infoDark} // давать ли информацию по абонементам Dark
                                client={true} // проверка есть ли клиент в БД, если нет, то не показывать инф об абонементе
                                admin={admin} // проверка страница admin или нет (true - admin)
                                clickInfo={false} // true - работает клик на абонемент и имя в качестве кнопки
                                textColor ={styleText} // стиль текста
                                styleWidth ={styleWidth}
                            // abonementDiscount={abonementDiscount}
                            />
                        </div>
                    })
                    : <></>
                }
            </div>
        </div >
    )
}



export default ClientsTable;
